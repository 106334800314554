<template>
  <div>

    <!-- View third party modal template -->
    <b-modal ref="view-tp"
             hide-footer
             modal-class="task-modal"
             @hide="hideTPModal"
    >
    <template #modal-header="{ close }">
      <div class="" style="width: 100%;">
        <span @click="hideTPModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
          <font-awesome-icon icon="times"/>
        </span>
        <h5 class="mb-1 text-center" qid="view-fc-title">
          {{ $t('maintenance.framework_control.title') }}
        </h5>
      </div>
    </template>

    <b-alert v-if="error.length"
             show
             variant="danger"
             qid="view-fc-error"
    >
      {{ error }}
    </b-alert>

    <div v-if="object">
      <div v-if="object.reference">
        <label>{{ $t('maintenance.framework_control.create.reference_label') }}</label>
          <p class="text-formatted">{{ object.reference }}</p>
        <br>
      </div>
      <div v-if="object.name">
        <label>{{ $t('maintenance.framework_control.create.name_label') }}</label>
          <p class="text-formatted">{{ object.name }}</p>
        <br>
      </div>
      <div v-if="object.description">
        <label>{{ $t('maintenance.framework_control.create.description_label') }}</label>
          <p class="text-formatted">{{ object.description }}</p>
        <br>
      </div>
      <div v-if="object.description">
        <label qid="view-framework-control-soa-label">
          {{ $t('maintenance.framework_control.create.statement_of_applicability_label') }}
        </label>
      </div>

      <p v-if="object.statement_of_applicability">
        <font-awesome-icon icon="check-circle" class="icon-success"/>
        {{ $t('system.yes') }}
      </p>
      <p v-if="!object.statement_of_applicability">
        <font-awesome-icon icon="times-circle" class="icon-danger"/>
        {{ $t('system.no') }}
      </p>
      <br>

      <div v-if="object.statement_of_applicability_description">
        <label qid="create-framework-control-description-label">
          {{ $t('maintenance.framework_control.create.soa_description_label') }}
        </label>
        <p class="text-formatted">{{ object.statement_of_applicability_description }}</p>
        <br>
      </div>
      <div v-if="object.implementation_status">
        <label qid="edit-p-implementation-status-label">
          {{ $t('maintenance.policies.create.implementation_status_label') }}
        </label>
        <div class="" qid="view-fc-implementation_status">
          <font-awesome-icon icon="exclamation-triangle" class="risk-1 mr-2" v-if="object.implementation_status == 'Fully implemented'"/>
          <font-awesome-icon icon="exclamation-triangle" class="risk-9 mr-2" v-if="object.implementation_status == 'Designed but not implemented' || object.implementation_status == 'Partially implemented'"/>
          <font-awesome-icon icon="exclamation-triangle" class="risk-16 mr-2" v-if="object.implementation_status == 'Not designed'"/>
          {{ $t('maintenance.policies.status.' + $options.filters.slugify(object.implementation_status)) }}
        </div>
        <br>
      </div>
    </div>
    <div class="proxy-footer">
      <div class="alert alert-menu-color color-secondary mb-0" role="alert">
        <div class="row">
          <div class="col-sm-6">

          </div>
          <div class="col-sm-6">
            <div class="text-right">
              <b-button class="float-right"
                        variant="secondary"
                        @click="hideTPModal"
                        qid="view-fc-cancel-button"
              >
                {{ $t('processes.policies.view.close') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>


</div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'

  export default {
    name: 'FrameworkControlViewModal',
    data: function() {
      return {
        error: '',
        showInput: false,
        inputLink: '',
        loadingItems: false,
        showArticleDescription: false,
        secondaryDO: [],
        selectedFileObject: {},
        selectedFileIndex: '',
        selectedLinkObject: {},
        selectedLinkIndex: '',
      }
    },
    props: {
      object: {
        type: [Object,Array]
      },
      showModal: {
        type: Boolean
      },
      active: {
        type: Boolean
      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when prop changes
      showModal: function (value) {
        if (value) {
          this.showTPModal()
        }
      }
    },
    methods: {
      showTPModal() {
        this.$refs['view-tp'].show()
      },
      hideTPModal() {
        this.error = ''
        this.showArticleDescription = false;
        this.showInput = false;
        this.$emit('closeViewModal');
        this.$refs['view-tp'].hide()
      }
    },
    components: {

    },
    created () {

    }
  }
</script>
