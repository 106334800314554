<template>
  <div>
    <!-- Risk scenarios mitigated table -->
    <div v-if="isGranted([permissions.GENERIC_CONTROL_RISK_SCENARIO_VIEW, permissions.GENERIC_CONTROL_RISK_SCENARIO_VIEW_OWN], object)"
         class="card pagination-margin"
         qid="risk-section"
         id="update_risk_scenarios"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <h5 qid="risk-table-title" class="mb-2">
              {{ $t('generic_controls.risk_scenarios.title') }}
            </h5>
            <span class="cursor-pointer task-label-open mr-2"
                  v-if="getTask('update_risk_scenarios')"
                  @click="openToolbar(getTask('update_risk_scenarios'))"
            >
              <font-awesome-icon icon="info-circle" class="text-primary"/>
              {{ $t('tasks.open_label') }}
            </span>
            <span v-if="getGuide('generic_controls_update_risk_scenarios')">
              <GuideButton :guide="getGuide('generic_controls_update_risk_scenarios')"></GuideButton>
            </span>
          </div>
          <div class="col-sm-6 text-right">
            <button class="btn btn-success ml-3 pl-3 pr-3"
                    qid="risk-create-button"
                    @click="showCreatePolicyModal"
                    v-if="isGranted([permissions.GENERIC_CONTROL_RISK_SCENARIO_CREATE, permissions.GENERIC_CONTROL_RISK_SCENARIO_CREATE_OWN], object) && (!getClient.parent || (getClient.parent && object.partition))"
            >
              <font-awesome-icon icon="plus"/> {{ $t('generic_controls.risk_scenarios.create_button') }}
            </button>
          </div>
        </div>

        <table class="table table-responsive-md table-list-secondary">
          <thead>
            <tr>
              <th scope="col"
                  class="cursor-pointer"
                  @click="selectSort('name')"
                  qid="sort-s-name"
              >
                {{ $t('generic_controls.risk_scenarios.name') }}
                <span class="ml-2">
                  <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'name'"/>
                  <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'name'"/>
                  <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'name'"/>
                </span>
              </th>
              <th scope="col"
                  class="cursor-pointer"
                  @click="selectSort('updated_at')"
                  qid="sort-s-name"
              >
                {{ $t('system.last_updated') }}
                <span class="ml-2">
                  <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'updated_at'"/>
                  <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'updated_at'"/>
                  <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'updated_at'"/>
                </span>
              </th>
              <th width="230"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(object, index) in riskTableItems"
                :id="'table-row-' + index"
                :class="{'alert-danger': !matchingScope(object)}"
            >
              <b-tooltip
                  v-if="!matchingScope(object)"
                  ref="tooltip"
                  :target="'table-row-' + index"
              >
                {{ $t('generic_controls.matching_scope_tooltip_error') }}
              </b-tooltip>
              <td scope="row">
                <button v-if="object.risk_scenario"
                        @click="showViewModal(object)"
                        qid="risk-name-link"
                        class="btn btn-link text-left"
                >
                  {{ object.risk_scenario.name }}
                </button>
              </td>
              <td>
                <span class="text-normal">
                  {{ object.risk_scenario.updated_at | formatDate }}
                </span>
              </td>
              <td align="right">
                <button class="btn btn-view"
                        qid="view-links-button"
                        @click="showViewLinksModal(object)"
                >
                  {{ $t('generic_controls.risk_scenarios.view_links_button') }}
                </button>

                <button v-if="object.risk_scenario"
                        @click="showViewModal(object)"
                        qid="risk-name-link"
                        class="btn btn-view"
                >
                  <font-awesome-icon icon="eye"/>
                </button>

                <button v-if="isGranted([permissions.GENERIC_CONTROL_RISK_SCENARIO_DELETE, permissions.GENERIC_CONTROL_RISK_SCENARIO_DELETE_OWN], object.generic_control) && (!getClient.parent || (getClient.parent && object.generic_control.partition))"
                        class="btn btn-delete"
                        qid="delete-risk_scenario-modal"
                        @click="showPolicyConfirmDeleteModal(object)"
                >
                  <font-awesome-icon icon="trash-alt"/>
                </button>
              </td>
            </tr>
            <tr v-if="!riskLoading && !riskTableItems.length" qid="no-results-row" class="text-center">
              <td colspan="10">
                <h5 class="mt-3">
                  {{ $t('system.no_results') }}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card-body">
        <div class="pagination row">
          <div class="col-md-6">
            <div class="row">
              <div class="float-left">
                <span class="text-muted pagination-counter">
                  {{ $t('pagination.entries_per_page') }}:
                </span>
              </div>
              <div class="float-left">
                <multiselect
                    v-model="perPage"
                    :options="perPageOptions"
                    track-by="value"
                    label="value"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-org-form-organization-level"
                    @select="perPageChanged"
                    :placeholder="selectedPerPage"
                    @remove="perPageRemoved"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="selectedPerPage"
                aria-controls="my-table"
                @change="pageChanged"
                limit="1"
            >
              <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
              <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
              <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
              <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
              <div slot="ellipsis-text">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
              <span class="text-muted" slot="page" slot-scope="{ page, active }">
                <span v-if="active">{{getPerPage}}</span>
              </span>
            </b-pagination>
          </div>
        </div>
      </div>

    </div>


    <!-- Delete risk modal template -->
    <b-modal ref="delete-risk" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-lb-title">
          {{ $t('generic_controls.risk_scenarios.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        {{ $t('generic_controls.risk_scenarios.delete.description') }}
        <span v-if="itemToDelete.risk_scenario">{{ itemToDelete.risk_scenario.name }}</span>
      </h5>
      <h5 class="text-center">
        {{ $t('generic_controls.risk_scenarios.delete.are_you_sure') }}
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hidePolicyConfirmDeleteModal"
                  qid="delete-risk-cancel-button"
        >
          {{ $t('generic_controls.risk_scenarios.delete.cancel') }}
        </b-button>
        <b-button @click="confirmDeletePolicy(itemToDelete)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-risk-submit-button"
                  v-if="isGranted([permissions.GENERIC_CONTROL_RISK_SCENARIO_DELETE, permissions.GENERIC_CONTROL_RISK_SCENARIO_DELETE_OWN], object)"
        >
          {{ $t('generic_controls.risk_scenarios.delete.submit') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Add new risk -->
    <b-modal ref="add-risk" hide-footer @hide="hideCreatePolicyModal">
      <div class="d-block text-center">
        <h4 qid="add-io-title">
          {{ $t('generic_controls.risk_scenarios.create.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-io-error"
      >
        {{ error }}
      </b-alert>
      <b-alert v-if="object && (!object.scopes || (object.scopes && !object.scopes.length))"
               show
               variant="danger"
               qid="scope-error"
      >
        {{ $t('generic_controls.missing_scope_error_message') }}
      </b-alert>
      <b-form @submit="riskFormSubmit" qid="create-risk-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-risk-pg-label">
              {{ $t('generic_controls.risk_scenarios.create.risk_label') }}
            </label>
            <multiselect
                v-model="selectedRiskScenario"
                :options="riskItems"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('generic_controls.risk_scenarios.create.risk_placeholder')"
                label="name"
                @search-change="loadRiskItems"
                track-by="id"
                qid="create-lb-form-pg"
            ></multiselect>
          </b-form-group>
        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideCreatePolicyModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('generic_controls.risk_scenarios.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-p-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('generic_controls.risk_scenarios.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>


    <!-- View links modal -->
    <b-modal ref="view-links" hide-footer @hide="hideViewLinksModal" size="lg">
      <div class="d-block text-center">
        <h4 qid="add-io-title">
          {{ $t('generic_controls.risk_scenarios.view.links_title') }}
        </h4>
        <h4 v-if="linksItem && linksItem.risk_scenario">
          '{{ linksItem.risk_scenario.name }}'
        </h4>
      </div>
      <b-tabs class="mt-3">
        <b-tab :title="$t('generic_controls.risk_scenarios.processes_label')" active @click="processTabClicked()">
          <div class="modal-tabs-scrollable pt-5">
            <div v-for="object in processItems" :key="object.id">
              <h5 class="mt-3">
                <router-link
                    :to="{ name: 'processes.view', params: { slug: object.process.slug, client: object.process.active_client.slug }}"
                    qid="s-view-page-link"
                >
                  {{ object.process.name }}
                </router-link>
              </h5>
              <hr>
            </div>
            <div class="text-center" @click="loadProcessItemsControl()" v-if="showMore && !loading">
              <a>{{ $t('system.show_more') }}</a>
            </div>
            <div class="text-center mt-1" v-if="processItems && !processItems.length && !loading">
              <a>{{ $t('system.no_results') }}</a>
            </div>
            <div class="text-center mt-1" v-if="loading">
              <font-awesome-icon icon="spinner" size="lg" class="fa-spin mr-2"/>
              {{ $t('system.loading') }}
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('activities.title')" @click="activityTabClicked()">
          <div class="modal-tabs-scrollable pt-5">
            <div v-for="object in activityItems" :key="object.id">
              <h5>
                <router-link
                    :to="{ name: 'activities.view', params: { slug: object.activity.slug, client: object.activity.active_client.slug }}"
                    qid="s-view-page-link"
                >
                  {{ object.activity.name }}
                </router-link>
              </h5>
              <hr>
            </div>
            <div class="text-center" @click="loadActivityItemsControl()" v-if="showMore && !loading">
              <a>{{ $t('system.show_more') }}</a>
            </div>
            <div class="text-center mt-1" v-if="activityItems && !activityItems.length && !loading">
              <a>{{ $t('system.no_results') }}</a>
            </div>
            <div class="text-center mt-1" v-if="loading">
              <font-awesome-icon icon="spinner" size="lg" class="fa-spin mr-2"/>
              {{ $t('system.loading') }}
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('systems.title')" @click="systemTabClicked()">
          <div class="modal-tabs-scrollable pt-5">
            <div v-for="object in systemItems" :key="object.id">
              <h5>
                <router-link
                    :to="{ name: 'systems.view', params: { slug: object.system.slug, client: object.system.active_client.slug }}"
                    qid="s-view-page-link"
                >
                  {{ object.system.name }}
                </router-link>
              </h5>
              <hr>
            </div>
            <div class="text-center" @click="loadSystemItemsControl()" v-if="showMore && !loading">
              <a>{{ $t('system.show_more') }}</a>
            </div>
            <div class="text-center mt-1" v-if="systemItems && !systemItems.length && !loading">
              <a>{{ $t('system.no_results') }}</a>
            </div>
            <div class="text-center mt-1" v-if="loading">
              <font-awesome-icon icon="spinner" size="lg" class="fa-spin mr-2"/>
              {{ $t('system.loading') }}
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('maintenance.third_parties.title')"  @click="thirdPartyTabClicked()">
          <div class="modal-tabs-scrollable pt-5">
            <div v-for="object in thirdPartyItems" :key="object.id">
              <h5>
                <router-link
                    :to="{ name: 'contracts.view', params: { slug: object.third_party.slug, client: object.third_party.active_client.slug }}"
                    qid="s-view-page-link"
                >
                  {{ object.third_party.name }}
                </router-link>
              </h5>
              <hr>
            </div>
            <div class="text-center" @click="loadThirdPartyItemsControl()" v-if="showMore && !loading">
              <a>{{ $t('system.show_more') }}</a>
            </div>
            <div class="text-center mt-1" v-if="thirdPartyItems && !thirdPartyItems.length && !loading">
              <a>{{ $t('system.no_results') }}</a>
            </div>
            <div class="text-center mt-1" v-if="loading">
              <font-awesome-icon icon="spinner" size="lg" class="fa-spin mr-2"/>
              {{ $t('system.loading') }}
            </div>
          </div>
        </b-tab>
        <div>
          <b-button class="mt-4 mb-4 float-right"
                    variant="success"
                    @click="hideViewLinksModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('processes.policies.view.close') }}
          </b-button>
        </div>
      </b-tabs>

    </b-modal>


    <!-- View Risk scenario modal -->
    <b-modal ref="view-modal" hide-footer @hide="hideViewModal" size="md">
      <div class="d-block text-center mb-4">
        <h4 qid="add-io-title">
          {{ $t('generic_controls.risk_scenarios.view.title') }}
        </h4>
        <h4 v-if="linksItem && linksItem.risk_scenario">
          '{{ linksItem.risk_scenario.name }}'
        </h4>
      </div>
      <b-form-group v-if="linksItem && linksItem.risk_scenario">
        <label qid="create-p-users-label">
          {{ $t('generic_controls.threats.description') }}
        </label>
        <div v-if="linksItem.risk_scenario.description && linksItem.risk_scenario.description.length > 300">
          <p v-if="showFullDescription" class="text-formatted">
            {{ linksItem.risk_scenario.description }}
          </p>
          <p v-if="!showFullDescription" class="text-formatted">
            {{ linksItem.risk_scenario.description | truncate(300) }}
          </p>
          <div class="text-center mt-3" @click="showFullDescription=!showFullDescription">
            <span class="cursor-pointer" v-if="!showFullDescription">
              <span class="mr-2">
                {{ $t('system.view_more') }}
              </span>
              <font-awesome-icon icon="chevron-down"/>
            </span>
            <span class="cursor-pointer" v-if="showFullDescription">
              <span class="mr-2">
                {{ $t('system.view_less') }}
              </span>
              <font-awesome-icon icon="chevron-up"/>
            </span>
          </div>
        </div>
        <p v-if="linksItem.risk_scenario.description && linksItem.risk_scenario.description.length <= 300"
           class="text-formatted"
        >
          {{ linksItem.risk_scenario.description }}
        </p>
      </b-form-group>
      <div>
        <b-button class="mt-4 mb-4 float-right"
                  variant="success"
                  @click="hideViewModal"
                  qid="create-p-cancel-button"
        >
          {{ $t('processes.policies.view.close') }}
        </b-button>
      </div>

    </b-modal>

  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import GuideButton from '@/components/guide/GuideButton.vue';


  export default {
    name: 'MitigationsRiskScenariosTable',
    data: function() {
      return {
        error: '',
        loadingItems: false,
        itemToDelete: {},
        buttonDisabled: false,
        showFullDescription: false,
        riskTableItems: [],
        riskLoading: true,
        riskValidationShow: false,
        showPolicyDescription: false,
        riskItems: [],
        linksItem: [],
        linksItems: [],
        selectedRiskScenario: [],
        viewPolicyModal: false,
        systemItems: [],
        processItems: [],
        activityItems: [],
        thirdPartyItems: [],
        page: 1,
        showMore: true,
        loading: true,
        selectedSort: '',
        selectedDirection: '',

        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],
        perPage: '',
        selectedPerPage: "10",
        currentPage: 1,
        totalRows: 0,
        myGuides: []
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      },
      tasks: {
        type: Array
      }
    },
    components: {
      Avatar,
      GuideButton
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      rows() {
        // Use computed value for rows because of dynamic update
        return this.totalRows
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getPerPage() {
        let page = this.currentPage;
        let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
        let selectedOf = this.totalRows;

        if(selectedTo > selectedOf){
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " of " + selectedOf
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadRiskItems();
      }
    },
    methods: {
      getTask(code) {
        let existingTask = _.find(this.tasks, function(object) {
          return object.code == code
        });
        return existingTask
      },
      openToolbar(object) {
        this.$store.commit('storeTaskToolbarState', true);
        this.$store.commit('storeActiveTask', object);
      },
      matchingScope(object) {
        let inArray = false
        _.forEach(object.generic_control.scopes, function (genericControlScope) {
          _.forEach(object.risk_scenario.types, function (riskScenarioScope) {
            if (genericControlScope == riskScenarioScope) {
              // if item is already in array
              inArray = true;
            }
          })
        })
        return inArray;
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Generic control' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Generic control',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
          }
        }, function() {});
      },
      resetFields() {
        this.selectedDropdownOption = null
      },
      selectSort(sort) {
        // If old and new sort are same, switch just direction
        if (sort === this.selectedSort) {
          this.selectedDirection = this.selectedDirection === 'desc' ? 'asc' : 'desc';
        } else {
          // If different sort, set sort and set direction to default
          this.selectedDirection = 'desc'
          this.selectedSort = sort;
        }
        this.loadRiskScenarios();
      },
      showViewLinksModal(object) {
        this.page = 1;
        this.showMore = true;
        this.systemItems = []
        this.processItems = []
        this.activityItems = []
        this.thirdPartyItems = []
        this.linksItem = object;
        this.loadProcessItemsControl()
        this.$refs['view-links'].show()
      },
      processTabClicked() {
        this.loadProcessItemsControl(true)
      },
      activityTabClicked() {
        this.loadActivityItemsControl(true)
      },
      systemTabClicked() {
        this.loadSystemItemsControl(true)
      },
      thirdPartyTabClicked() {
        this.loadThirdPartyItemsControl(true)
      },
      hideViewLinksModal() {
        this.linksItem = {};
        this.$refs['view-links'].hide()
      },
      showViewModal(object) {
        this.linksItem = object;
        this.$refs['view-modal'].show()
      },
      hideViewModal() {
        this.linksItem = []
        this.$refs['view-modal'].hide()
      },
      showCreatePolicyModal() {
        this.loadRiskItems();
        this.$refs['add-risk'].show()
      },
      hideCreatePolicyModal() {
        this.error = ''
        this.selectedRiskScenario = [];
        this.showPolicyDescription = false;
        this.$refs['add-risk'].hide()
      },
      showPolicyConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.$refs['delete-risk'].show()
      },
      hidePolicyConfirmDeleteModal() {
        this.itemToDelete = {}
        this.$refs['delete-risk'].hide()
      },
      pageChanged(page) {
        // Load Third parties when page changed listener is triggered
        this.loadRiskScenarios(page)
      },
      perPageChanged(perPage) {
        let self = this;

        self.selectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.selectedPerPage);
        // Load Third parties when page changed listener is triggered
        this.loadRiskScenarios(1, perPage.value)
      },
      perPageRemoved() {
        let self = this;
        // Load Third parties when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value
        this.loadRiskScenarios(1, self.selectedPerPage)

      },
      loadRiskScenarios(page, perPage) {

        if (this.getStorePerPage) {
          this.selectedPerPage = this.getStorePerPage;
        }

        // If page is not defined, set page to default 1
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.selectedPerPage;
        let self = this;
        piincHttp.get('generic-controls/' + this.$route.params.slug + '/risk-scenarios', { params:
          {
            page: pageNumber,
            per_page: perPageNumber,
            sort: this.selectedSort !== '' ? this.selectedSort : undefined,
            order: this.selectedDirection !== '' ? this.selectedDirection : undefined,
            client: this.getClient.slug,
          }
        }).then(function(response) {
          self.riskTableItems = response.data.items;
          self.riskLoading = false;
          self.totalRows = response.data.total_count;
        }, function() {});
      },
      confirmDeletePolicy(object) {
        let self = this;
        piincHttp.delete('generic-controls/' + this.object.slug + '/risk-scenarios/' + object.id).then(function() {
          self.hidePolicyConfirmDeleteModal();
          self.loadRiskScenarios(self.currentPage);
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('generic_controls.risk_scenarios.delete.success'))
        }, function() {});
      },
      riskFormSubmit(evt) {
        this.buttonDisabled = true;
        let formData = new FormData();
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        if (this.selectedRiskScenario && this.selectedRiskScenario.id) {
          formData.append("risk_scenario_id", this.selectedRiskScenario.id);
        }

        formData.append("genericControl", this.$route.params.slug);

        piincHttp.post('/generic-controls/' + this.$route.params.slug + '/risk-scenarios', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function(response) {
          self.$toastr('success', self.$t('generic_controls.risk_scenarios.create.success'))
          self.hideCreatePolicyModal();
          self.buttonDisabled = false;
          self.loadRiskScenarios();
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
            if (error.data.errors.risk_id) {
              self.error += ' ' + error.data.errors.risk_id[0]
            }
          }
        })
      },
      loadRiskItems(query) {
        let self = this;
        if (this.object && this.object.scopes && this.object.scopes.length) {
          piincHttp.get('risk-scenarios', { params:
            {
              client: this.getClient.slug,
              per_page: 100,
              keyword: query ? query : undefined,
              serialize_group: 'member',
              types: this.object.scopes
            }
          }).then(function(response) {
            self.riskItems = response.data.items
            if (self.riskItems && !self.riskItems.length && !query) {
              self.riskItems.push({name: self.$t('system.no_results'), $isDisabled: true})
            }
          }, function() {});
        }
      },
      loadSystemItemsControl(reset) {
        if (reset) {
          this.showMore = true
          this.page = 1
          this.systemItems = []
        }
        let self = this;
        piincHttp.get('risk-scenarios/' + this.linksItem.risk_scenario.slug + '/business-risks', { params:
          {
            type: "system",
            client: this.getClient.slug,
            page: this.page,
            model_type: this.linksItem.generic_control.who_am_i,
            model_id: this.linksItem.generic_control.id
          }
        }).then(function(response) {
          if (response.data.items && (response.data.items.length < 10 || (response.data.items.length == 10 && response.data.total_count == 10 * self.page))) {
            self.showMore = false;
          }

          self.page += 1;
          self.loading = false;
          self.systemItems.push(...response.data.items)

        }, function() {});
      },
      loadProcessItemsControl(reset) {
        if (reset) {
          this.showMore = true
          this.page = 1
          this.processItems = []
        }
        let self = this;
        piincHttp.get('risk-scenarios/' + this.linksItem.risk_scenario.slug + '/business-risks', { params:
          {
            type: "process",
            client: this.getClient.slug,
            page: this.page,
            model_type: this.linksItem.generic_control.who_am_i,
            model_id: this.linksItem.generic_control.id
          }
        }).then(function(response) {
          if (response.data.items && (response.data.items.length < 10 || (response.data.items.length == 10 && response.data.total_count == 10 * self.page))) {
            self.showMore = false;
          }

          self.page += 1;
          self.loading = false;
          self.processItems.push(...response.data.items)
        }, function() {});
      },
      loadActivityItemsControl(reset) {
        if (reset) {
          this.showMore = true
          this.page = 1
          this.activityItems = []
        }
        let self = this;
        piincHttp.get('risk-scenarios/' + this.linksItem.risk_scenario.slug + '/business-risks', { params:
          {
            type: "activity",
            client: this.getClient.slug,
            page: this.page,
            model_type: this.linksItem.generic_control.who_am_i,
            model_id: this.linksItem.generic_control.id
          }
        }).then(function(response) {
          if (response.data.items && (response.data.items.length < 10 || (response.data.items.length == 10 && response.data.total_count == 10 * self.page))) {
            self.showMore = false;
          }

          self.page += 1;
          self.loading = false;
          self.activityItems.push(...response.data.items)
        }, function() {});
      },
      loadThirdPartyItemsControl(reset) {
        if (reset) {
          this.showMore = true
          this.page = 1
          this.thirdPartyItems = []
        }
        let self = this;
        piincHttp.get('risk-scenarios/' + this.linksItem.risk_scenario.slug + '/business-risks', { params:
          {
            type: "thirdParty",
            client: this.getClient.slug,
            page: this.page,
            model_type: this.linksItem.generic_control.who_am_i,
            model_id: this.linksItem.generic_control.id
          }
        }).then(function(response) {
          if (response.data.items && (response.data.items.length < 10 || (response.data.items.length == 10 && response.data.total_count == 10 * self.page))) {
            self.showMore = false;
          }

          self.page += 1;
          self.loading = false;
          self.thirdPartyItems.push(...response.data.items)
        }, function() {});
      }
    },
    created () {
      if (this.getClient && this.getClient.slug) {

      }
      // Table items
      this.loadRiskScenarios();
      this.loadGuides('generic_controls_update_risk_scenarios');
    }
  }
</script>
