<template>
  <span>
    <span class="btn btn-edit" qid="edit-tp-modal" @click="showEditPModal(object)">
      <font-awesome-icon icon="pen"/>
    </span>

    <!-- Edit Process modal template -->
    <b-modal ref="edit-process" hide-footer @hide="hideEditPModal">
      <div class="d-block text-center">
        <h4 qid="update-tp-title">
          {{ $t('generic_controls.update.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="formSubmit" qid="create-p-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-p-name-label">
              {{ $t('generic_controls.create.name_label') }}
            </label>
            <b-form-input
                qid="create-p-form-name"
                type="text"
                v-model="pForm.name"
                :maxLength="255"
                required
                :placeholder="$t('generic_controls.create.name_placeholder')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="create-do-tags-label">
              {{ $t('processes.create.tags_label') }}
            </label>
            <multiselect
                v-model="selectedTags"
                :options="tags"
                :multiple="true"
                :taggable="true"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @search-change="loadTags"
                track-by="name_translated"
                :placeholder="$t('processes.create.tags_placeholder')"
                label="name_translated"
                qid="create-do-tags-select"
                @tag="addTag"
            ></multiselect>
          </b-form-group>
          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('generic_controls.create.owners_label') }}
            </label>
            <multiselect
                v-model="selectedUsers"
                :options="users"
                :multiple="true"
                :taggable="false"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @search-change="loadUsers"
                @remove="removeUserFromDropdown"
                track-by="slug"
                :placeholder="$t('generic_controls.create.owners_placeholder')"
                label="name"
                qid="create-p-users-select"
            >
              <template slot="tag" slot-scope="props">
                <span></span>
              </template>
              <template slot="option" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
            <div class="form-control auto-height mt-1" v-if="selectedUsers && selectedUsers.length">
              <div v-for="(user, index) in selectedUsers" class="mt-1 mb-1">
                <div class="row">
                  <div class="col-sm-1">
                    <font-awesome-icon
                        icon="star"
                        :class="{'color-yellow': user.owner, 'text-light': !user.owner}"
                        class="cursor-pointer"
                        @click="setOwner(index)"
                    />
                  </div>
                  <div class="col-sm-10">
                    <Avatar :object="user" size="xs" :noOwner="true"></Avatar>
                    <span class="ml-2">{{ user.name }}</span>
                  </div>
                  <div class="col-sm-1">
                    <font-awesome-icon
                        icon="times"
                        class="cursor-pointer"
                        @click="removeUser(index)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <span class="text-light">
              * {{ $t('generic_controls.create.owners_tip') }}
            </span>
          </b-form-group>
          <b-form-group>
            <label qid="create-tp-type-label">
              {{ $t('generic_controls.create.category_label') }}
            </label>
            <multiselect
                v-model="selectedTypes"
                :options="types"
                :multiple="true"
                :taggable="false"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                track-by="value"
                :placeholder="$t('generic_controls.create.category_placeholder')"
                label="name"
                qid="generic-controls-type-select"
            ></multiselect>
          </b-form-group>
          <b-form-group>
            <label qid="generic-controls-type-label">
              {{ $t('generic_controls.create.control_type_label') }}
            </label>
            <multiselect
                v-model="selectedCategory"
                :options="categories"
                :multiple="false"
                :taggable="false"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                track-by="value"
                :placeholder="$t('generic_controls.create.control_type_placeholder')"
                label="name"
                qid="generic-controls-type-select"
            ></multiselect>
            <span class="text-light">
              * {{ $t('generic_controls.create.category_tip') }}
            </span>
          </b-form-group>
          <b-form-group v-if="selectedCategory && selectedCategory.value == 'Control'">
            <label qid="create-tp-type-label">
              {{ $t('table.scope') }}
            </label>
            <multiselect
                v-model="selectedScopes"
                :options="scopes"
                :multiple="true"
                track-by="value"
                :taggable="true"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('table.scope')"
                label="name"
                qid="create-tp-form-type"
            >
              <template slot="noResult" slot-scope="props">
                {{ $t('maintenance.data_subjects.create.subjects_no_results') }}
              </template>
            </multiselect>
          </b-form-group>
        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideEditPModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('generic_controls.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-p-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('generic_controls.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </span>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';

  export default {
    name: 'GenericCintrolsEditForm',
    data: function() {
      return {
        pForm: {
          slug: '',
          name: '',
          control_types: [],
          type: '',
          users: [],
          tags: [],
          scopes: [],
        },
        error: '',
        selectedUsers: [],
        loadingItems: false,
        users: [],
        editParam: false,
        modalShown: false,
        buttonDisabled: false,
        types: [
          { name: this.$t('generic_controls.control_type.preventive'), value: 'preventive' },
          { name: this.$t('generic_controls.control_type.detective'), value: 'detective' },
          { name: this.$t('generic_controls.control_type.corrective'), value: 'corrective' }
        ],
        scopes: [
          { name: this.$t('risk_scenarios.type.process'), value: 'process' },
          { name: this.$t('risk_scenarios.type.activity'), value: 'activity' },
          { name: this.$t('risk_scenarios.type.third_party'), value: 'third_party' }
        ],
        selectedTypes: [],
        selectedScopes: [],
        categories: [
          { name: this.$t('generic_controls.category.control'), value: 'Control' },
          { name: this.$t('generic_controls.category.security_measure'), value: 'Security measure' }
        ],
        selectedCategory: [],
        selectedTags: [],
        tags: [],
        newTags: [],
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      },
      edit: {
        type: Boolean,
      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadTags();
        this.loadUsers();
        if (this.scopes && this.scopes.length == 3) {
          if (this.getClient.systemModule) {
            this.scopes.push({ name: this.$t('risk_scenarios.type.system'), value: 'system' },)
          }
          if (this.getClient.policyModule) {
            this.scopes.push({ name: this.$t('risk_scenarios.type.policy'), value: 'policy' },)
          }
        }
      },
      object: function (value) {
        let self = this;
        if (value && value.id) {
          if (this.editParam && !this.modalShown) {
            setTimeout(function(){
              self.showEditPModal(self.object)
              self.modalShown = true;
            }, 500);
          }
        }
      }
    },
    methods: {
      addTag(newTag) {
        const tag = {
          name: newTag,
          translatable: newTag,
          name_translated: newTag
        }
        this.selectedTags.push(tag)
        this.newTags.push(tag)
      },
      removeUser(index) {
        let selectedItem = this.selectedUsers[index]
        selectedItem.owner = false;
        this.$set(this.selectedUsers, index, selectedItem)
        // Remove item from selected users array
        this.selectedUsers.splice(index, 1);
      },
      removeUserFromDropdown(item) {
        item.owner = false;
      },
      setOwner(index) {
        let selectedItem = this.selectedUsers[index]
        _.forEach(this.selectedUsers, function (item) {
          item.owner = false;
        })
        selectedItem.owner = true;
        this.$set(this.selectedUsers, index, selectedItem)
      },
      showEditPModal(object) {
        let self = this;
        this.selectedUsers = []

        this.loadUsers();
        this.loadTags();

        if (object.users && object.users.length) {
          self.selectedUsers = [];
          _.forEach(object.users, function (user) {
            let matchedUser = _.cloneDeep(user)
            if (user.id == object.owner) {
              matchedUser.owner = true
            }
            self.selectedUsers.push(matchedUser)
          })
        }

        if (object.scopes && object.scopes.length) {
          self.selectedScopes = [];
          _.forEach(object.scopes, function (value, key) {
            _.find(self.scopes, function(item) {
              if (item.value === value) {
                self.selectedScopes.push(item)
              }
            });
          })
        }

        if (object.tags_translated && object.tags_translated.length) {
          this.selectedTags = _.cloneDeep(object.tags_translated);
        }

        this.selectedCategory = []
        if (object.type) {
          this.selectedCategory = _.find(this.categories, function(item) {
            return item.value === object.type;
          });
        }

        // set selected countries on edit form
        if (object.control_types && object.control_types.length) {
          self.selectedTypes = [];
          _.forEach(object.control_types, function (value, key) {
            _.find(self.types, function(item) {
              if (item.value === value.toLowerCase()) {
                self.selectedTypes.push(item)
              }
            });
          })
        }

        this.pForm.slug = object.slug;
        this.pForm.name = object.name;
        this.pForm.description = object.description;
        this.$refs['edit-process'].show()

      },
      hideEditPModal() {
        this.pForm = {}
        this.error = '',
        this.selectedUsers = [],
        this.editParam = false;
        this.$refs['edit-process'].hide()
      },
      formSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        let selectedOwner = false;
        evt.preventDefault();

        this.pForm.tags = [];
        if (this.selectedTags && this.selectedTags.length) {
          for (var i = 0; i < this.selectedTags.length; i++) {
            this.pForm.tags.push(this.selectedTags[i].name_translated);
          }
        }

        this.pForm.users = [];
        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            this.pForm.users.push(this.selectedUsers[i].id);
            if (this.selectedUsers[i].owner) {
              selectedOwner = true;
              this.pForm.owner = this.selectedUsers[i].id;
            }
          }
        } else {
          selectedOwner = true;
        }

        this.pForm.control_types = [];
        if (this.selectedTypes && this.selectedTypes.length) {
          for (var i = 0; i < this.selectedTypes.length; i++) {
            this.pForm.control_types.push(this.selectedTypes[i].value);
          }
        }

        this.pForm.type = '';
        if (this.selectedCategory && this.selectedCategory.value) {
          this.pForm.type = this.selectedCategory.value
        }

        this.pForm.scopes = [];
        if (this.selectedScopes && this.selectedScopes.length && this.selectedCategory.value == 'Control') {
          for (var i = 0; i < this.selectedScopes.length; i++) {
            this.pForm.scopes.push(this.selectedScopes[i].value);
          }
        }

        if (selectedOwner && this.selectedCategory && this.selectedCategory.value && ((this.selectedCategory.value == 'Control' && this.selectedScopes.length) || this.selectedCategory.value == 'Security measure')) {
          piincHttp.put('generic-controls/' + this.pForm.slug, this.pForm).then(function(response) {
            self.hideEditPModal();
            // Emit event to parent component to update newest validation
            self.$emit('reloadContent', response.data);
            self.$toastr('success', self.$t('generic_controls.update.success'))
            self.buttonDisabled = false;
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.error = '';
          if (!selectedOwner) {
            this.error += this.$t('system.owners_error_message')
          }
          if (this.selectedCategory && this.selectedCategory.value == 'Control' && !this.selectedScopes.length) {
            this.error +=  ' ' + this.$t('system.scope_error_message')
          }

          if (!this.selectedCategory || !this.selectedCategory.value) {
            this.error +=  ' ' + this.$t('system.category_error_message')
          }
          this.buttonDisabled = false;
        }
      },
      loadTags(query) {
        let self = this;
        piincHttp.get('tags', { params:
          {
            client: this.getClient.slug,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.tags = response.data
          if (self.tags && !self.tags.length && !query) {
            self.tags.push({name_translated: self.$t('system.no_results'), $isDisabled: true})
          }
          for (var i = 0; i < self.newTags.length; i++) {
            self.tags.push(self.newTags[i]);
          }
        }, function() {});
      },
      loadUsers(query) {
        let self = this;
        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            per_page: 50
          }
        }).then(function(response) {
          self.users = response.data.items
          if (self.users && !self.users.length && !query) {
            self.users.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
    },
    components: {
      Avatar
    },
    created () {
      this.editParam = this.edit;
      let self = this;
      if (this.object && this.object.id) {
        if (this.editParam && !this.modalShown) {
          setTimeout(function(){
            self.showEditPModal(self.object)
            self.modalShown = true;
          }, 500);
        }
      }
      if (this.getClient && this.getClient.slug) {
        if (this.getClient.systemModule) {
          this.scopes.push({ name: this.$t('risk_scenarios.type.system'), value: 'system' },)
        }
        if (this.getClient.policyModule) {
          this.scopes.push({ name: this.$t('risk_scenarios.type.policy'), value: 'policy' },)
        }
      }
    }
  }
</script>
