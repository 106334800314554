<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div>
          <b-list-group>
            <b-list-group-item
                v-for="activity in items"
                :key="activity.id"
                class="border-left-0 border-right-0 border-top border-bottom-0 pt-4 pb-4"
            >

              <GenericControlActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\GenericControl\\Models\\GenericControl'"/>
              <GenericControlFrameworkActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\GenericControl\\Models\\GenericControlFramework'"/>
              <GenericControlRiskScenarioActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\GenericControl\\Models\\GenericControlRiskScenario'"/>
              <GenericControlPolicyActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\GenericControl\\Models\\GenericControlPolicy'"/>
              <GenericControlThreatActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\GenericControl\\Models\\GenericControlThreat'"/>
              <GenericControlVulnerabilityActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\GenericControl\\Models\\GenericControlVulnerability'"/>
              <GenericControlValidationActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Validation\\Models\\Validation'"/>
              <GenericControlMeasureActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\GenericControl\\Models\\GenericControlMeasure'"/>
            </b-list-group-item>

          </b-list-group>
          <hr class="mt-0"/>
        </div>
        <InfiniteLoading @infinite="loadingHandler">
          <template slot="no-results">
            <span>
              {{ $t('system.no_data') }}
            </span>
          </template>
        </InfiniteLoading>
      </div>
    </div>
  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import GenericControlActivity from '@/components/activity/genericcontrol/GenericControl.vue';
  import GenericControlFrameworkActivity from '@/components/activity/genericcontrol/GenericControlFramework.vue';
  import GenericControlRiskScenarioActivity from '@/components/activity/genericcontrol/GenericControlRiskScenario.vue';
  import GenericControlPolicyActivity from '@/components/activity/genericcontrol/GenericControlPolicy.vue';
  import GenericControlThreatActivity from '@/components/activity/genericcontrol/GenericControlThreat.vue';
  import GenericControlVulnerabilityActivity from '@/components/activity/genericcontrol/GenericControlVulnerability.vue';
  import GenericControlValidationActivity from '@/components/activity/genericcontrol/GenericControlValidation.vue';
  import GenericControlMeasureActivity from '@/components/activity/genericcontrol/GenericControlMeasure.vue';
  import InfiniteLoading from 'vue-infinite-loading';

  export default {
    name: 'GenericControlHistory',
    data: function() {
      return {
        genericControl: {},
        items: [],
        currentPage: 1,
        totalRows: 0,
        page: 1
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      }
    },
    components: {
      GenericControlActivity,
      GenericControlFrameworkActivity,
      GenericControlRiskScenarioActivity,
      GenericControlPolicyActivity,
      GenericControlThreatActivity,
      GenericControlVulnerabilityActivity,
      GenericControlValidationActivity,
      GenericControlMeasureActivity,
      InfiniteLoading
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {

      }
    },
    methods: {
      loadingHandler($state) {
        let self = this
        piincHttp.get('activity-log', { params:
          {
            log: this.genericControl.who_am_i + '::' + this.genericControl.id,
            page: this.page,
            client: self.getClient.slug
          }
        }).then(({ data }) => {
          if (data.items.length) {
            self.page += 1;
            self.items.push(...data.items);
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        });
      }
    },
    created () {
      this.genericControl = this.object;
    }
  }
</script>
