<template>
  <div>

    <div v-if="!show404 && !show403">
      <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-7">
              <h4 qid="p-view-page-title" class="mb-3">
                {{ genericControl.name }}
              </h4>
              <div class="mb-2">
                <span class="cursor-pointer task-label-open mr-2"
                      v-if="getTask('update_basic_info')"
                      @click="openToolbar(getTask('update_basic_info'))"
                >
                  <font-awesome-icon icon="info-circle" class="text-primary"/>
                  {{ $t('tasks.open_label') }}
                </span>
                <span v-if="getGuide('generic_controls_update_basic_info')">
                  <GuideButton :guide="getGuide('generic_controls_update_basic_info')"></GuideButton>
                </span>
              </div>
              <span v-if="genericControl.users && genericControl.users.length" class="mr-2">
                {{ $t('table.owners') }}:
              </span>
              <span class="mr-1" v-for="user in genericControl.users" qid="genericControl-users-list">
                <Avatar :object="user" size="xs"></Avatar>
              </span>
              <span v-if="genericControl.tags_translated && genericControl.tags_translated.length" class="mr-2 ml-2">
                {{ $t('table.tags') }}:
              </span>
              <span v-if="genericControl.tags_translated && genericControl.tags_translated.length" qid="activity-tags-list">
                <span class="badge" v-for="tag in getReducedTags(genericControl.tags_translated)" :title="tag.name_translated">
                  <font-awesome-icon icon="tag"/>
                  {{ tag.name_translated | truncate(35) }}
                </span>
                <span v-if="genericControl.tags_translated.length > 3"
                      class="badge cursor-pointer"
                      @click="openTagsModal(genericControl)"
                >
                  <font-awesome-icon icon="tags"/>
                  + {{ genericControl.tags_translated.length - 3 }}
                </span>
              </span>
              <span v-if="genericControl.scopes && genericControl.scopes.length && genericControl.type == 'Control'" class="mr-2 ml-2">
                {{ $t('table.scope') }}:
              </span>
              <span class="mr-1" qid="riskScenario-tags-list" v-if="genericControl.scopes && genericControl.scopes.length && genericControl.type == 'Control'">
                <span class="mr-1" v-for="scope in genericControl.scopes" qid="p-tags-list">
                  <span class="badge">
                    <font-awesome-icon icon="crosshairs"/>
                    {{ $t('risk_scenarios.type.' + $options.filters.slugify(scope)) }}
                  </span>
                </span>
              </span>
              <span v-if="genericControl.control_types && genericControl.control_types.length" class="mr-2 ml-2">
                {{ $t('generic_controls.table.type') }}:
              </span>
              <span class="mr-1" qid="genericControl-tags-list" v-if="genericControl.control_types && genericControl.control_types.length">
                <span class="mr-1" v-for="type in genericControl.control_types" qid="p-tags-list">
                  <span class="badge">
                    <font-awesome-icon icon="shapes"/>
                    {{ $t('generic_controls.control_type.' + $options.filters.slugify(type)) }}
                  </span>
                </span>
              </span>
              <span v-if="genericControl.type" class="mr-2 ml-2">
                {{ $t('generic_controls.table.control_type') }}:
              </span>
              <span class="mr-1" qid="genericControl-tags-list" v-if="genericControl.type">
                <span class="badge">
                  <font-awesome-icon icon="shield-alt"/>
                  {{ $t('generic_controls.category.' + $options.filters.slugify(genericControl.type)) }}
                </span>
              </span>
            </div>
            <div class="col-md-5 text-right" qid="view-s-modified">
              <div class="mb-4">
                <span class="h6 ml-3">
                  <GenericControlsEditForm
                      :object="genericControl"
                      :edit="edit"
                      @reloadContent="reloadContent"
                      v-if="isGranted([permissions.GENERIC_CONTROL_EDIT, permissions.GENERIC_CONTROL_EDIT_OWN], genericControl) && (!getClient.parent || (getClient.parent && genericControl.partition))"
                  ></GenericControlsEditForm>
                  <span class="btn btn-delete"
                        qid="delete-s-button"
                        @click="showConfirmDeleteModal(genericControl)"
                        v-if="isGranted([permissions.GENERIC_CONTROL_DELETE, permissions.GENERIC_CONTROL_DELETE_OWN], genericControl) && !genericControl.hasRunningProjects && (!getClient.parent || (getClient.parent && genericControl.partition))"
                  >
                    <font-awesome-icon icon="trash-alt"/>
                  </span>
                </span>
              </div>
              <div>
                <span>{{ $t('table.last_updated') }}</span>
                  <span class="ml-2 text-muted small" qid="view-s-modified-date">
                  {{ genericControl.updated_at | formatDate }}
                </span>
                <Avatar v-if="genericControl.updated_by"
                        :object="genericControl.updated_by"
                        size="xs"
                        class="ml-3 mr-2"
                ></Avatar>
                <span v-if="genericControl.updated_by" qid="view-s-modified-by">
                  {{ genericControl.updated_by.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-tabs class="mt-3" v-model="tabIndex">

        <!-- Basic info tab -->
        <b-tab :title="$t('projects.tab.basic_info')" active @click="basicTabClicked">

          <div class="card scope mt-3">
            <div class="card-header">
              <span class="float-right btn btn-success"
                    @click="showFormFunction"
                    qid="risk-edit-button"
                    v-if="isGranted([permissions.GENERIC_CONTROL_EDIT, permissions.GENERIC_CONTROL_EDIT_OWN], genericControl) && (!getClient.parent || (getClient.parent && genericControl.partition))"
              >
                <font-awesome-icon icon="pen"/> {{ $t('system.edit') }}
              </span>
              <div class="float-left">
                <h5 class="mb-2" qid="integrity-check-table-title">
                  {{ $t('generic_controls.control_description.title') }}
                </h5>
                <span v-if="getGuide('generic_controls_update_description')">
                  <GuideButton :guide="getGuide('generic_controls_update_description')"></GuideButton>
                </span>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="card-body">

                  <div class="mw-500" v-if="showForm">
                    <b-alert v-if="error.length"
                             show
                             variant="danger"
                             qid="create-s-error"
                    >
                      {{ error }}
                    </b-alert>
                    <label for="">{{ $t('generic_controls.control_description.control_description_label') }}</label>
                    <b-form-textarea
                        qid="description"
                        v-model="form.description"
                        rows="6"
                        :placeholder="$t('generic_controls.control_description.control_description_placeholder')"
                    ></b-form-textarea>
                    <br>
                    <label for="">{{ $t('generic_controls.control_description.passed_description_label') }}</label>
                    <b-form-textarea
                        qid="passed-description"
                        v-model="form.passed_description"
                        rows="6"
                        :placeholder="$t('generic_controls.control_description.passed_description_placeholder')"
                    ></b-form-textarea>
                    <br>
                    <label for="">{{ $t('generic_controls.control_description.failed_description_label') }}</label>
                    <b-form-textarea
                        qid="failed-description"
                        v-model="form.failed_description"
                        rows="6"
                        :placeholder="$t('generic_controls.control_description.failed_description_label')"
                    ></b-form-textarea>
                    <br />
                    <label for="">{{ $t('maintenance.questions.create.add_description_label') }}</label>
                    <multiselect
                        v-model="addDescription"
                        :options="allowTypes"
                        :placeholder="$t('maintenance.questions.create.add_description_placeholder')"
                        label="name"
                        :select-label="$t('system.dropdown_select_label')"
                        :selected-label="$t('system.dropdown_selected_label')"
                        :deselect-label="$t('system.dropdown_deselect_label')"
                        qid="create-p-form-pg"
                    ></multiselect>
                    <br />
                    <label for="">{{ $t('maintenance.questions.create.add_attachments_label') }}</label>
                    <multiselect
                        v-model="addAttachments"
                        :options="allowTypes"
                        :placeholder="$t('maintenance.questions.create.add_attachments_placeholder')"
                        label="name"
                        :select-label="$t('system.dropdown_select_label')"
                        :selected-label="$t('system.dropdown_selected_label')"
                        :deselect-label="$t('system.dropdown_deselect_label')"
                        qid="create-p-form-pg"
                    ></multiselect>
                    <div class="text-right">
                      <b-button class="mt-3"
                                variant="secondary"
                                @click="cancelForm"
                                qid="confidentiality-form-cancel-button"
                      >
                        {{ $t('processes.legal_basis.create.cancel') }}
                      </b-button>
                      <b-button class="mt-3 ml-3"
                                variant="success"
                                @click="formSubmit"
                                qid="confidentiality-form-submit-button"
                      >
                        {{ $t('processes.legal_basis.create.submit') }}
                      </b-button>
                    </div>
                  </div>
                  <div v-if="!showForm">
                    <label for="">{{ $t('generic_controls.control_description.control_description_label') }}</label>
                    <div class="section-container">
                      <div v-if="genericControl.description && genericControl.description.length > descriptionLength">
                        <p v-if="showFullDescription" class="text-formatted">{{ genericControl.description }}</p>
                        <p v-if="!showFullDescription" class="text-formatted">{{ genericControl.description | truncate(descriptionLength) }}</p>
                        <div class="text-center view-more mt-1" @click="showFullDescription=!showFullDescription">
                          <span class="cursor-pointer" v-if="!showFullDescription">
                            <span class="mr-2">
                              {{ $t('system.view_more') }}
                            </span>
                            <font-awesome-icon icon="chevron-down"/>
                          </span>
                          <span class="cursor-pointer" v-if="showFullDescription">
                            <span class="mr-2">
                              {{ $t('system.view_less') }}
                            </span>
                            <font-awesome-icon icon="chevron-up"/>
                          </span>
                        </div>
                      </div>
                      <p v-if="genericControl.description && genericControl.description.length <= descriptionLength"
                         class="text-formatted"
                      >{{ genericControl.description }}</p>
                      <p v-if="!genericControl.description" class="text-muted">
                        {{ $t('system.missing_input') }}
                      </p>
                    </div>

                    <br>
                    <label for="">{{ $t('generic_controls.control_description.passed_description_label') }}</label>
                    <div class="section-container">
                      <div v-if="genericControl.passed_description && genericControl.passed_description.length > descriptionLength">
                        <p v-if="showFullPassedDescription" class="text-formatted">{{ genericControl.passed_description }}</p>
                        <p v-if="!showFullPassedDescription" class="text-formatted">{{ genericControl.passed_description | truncate(descriptionLength) }}</p>
                        <div class="text-center view-more mt-1" @click="showFullPassedDescription=!showFullPassedDescription">
                          <span class="cursor-pointer" v-if="!showFullPassedDescription">
                            <span class="mr-2">
                              {{ $t('system.view_more') }}
                            </span>
                            <font-awesome-icon icon="chevron-down"/>
                          </span>
                          <span class="cursor-pointer" v-if="showFullPassedDescription">
                            <span class="mr-2">
                              {{ $t('system.view_less') }}
                            </span>
                            <font-awesome-icon icon="chevron-up"/>
                          </span>
                        </div>
                      </div>
                      <p v-if="genericControl.passed_description && genericControl.passed_description.length <= descriptionLength"
                         class="text-formatted"
                      >{{ genericControl.passed_description }}</p>
                      <p v-if="!genericControl.passed_description" class="text-muted">
                        {{ $t('system.missing_input') }}
                      </p>
                    </div>
                    <br />
                    <label for="">{{ $t('generic_controls.control_description.failed_description_label') }}</label>
                    <div class="section-container">
                      <div v-if="genericControl.failed_description && genericControl.failed_description.length > descriptionLength">
                        <p v-if="showFullFailedDescription" class="text-formatted">{{ genericControl.failed_description }}</p>
                        <p v-if="!showFullFailedDescription" class="text-formatted">{{ genericControl.failed_description | truncate(descriptionLength) }}</p>
                        <div class="text-center view-more mt-1" @click="showFullFailedDescription=!showFullFailedDescription">
                          <span class="cursor-pointer" v-if="!showFullFailedDescription">
                            <span class="mr-2">
                              {{ $t('system.view_more') }}
                            </span>
                            <font-awesome-icon icon="chevron-down"/>
                          </span>
                          <span class="cursor-pointer" v-if="showFullFailedDescription">
                            <span class="mr-2">
                              {{ $t('system.view_less') }}
                            </span>
                            <font-awesome-icon icon="chevron-up"/>
                          </span>
                        </div>
                      </div>
                      <p v-if="genericControl.failed_description && genericControl.failed_description.length <= descriptionLength"
                         class="text-formatted"
                      >{{genericControl.failed_description}}</p>
                      <p v-if="!genericControl.failed_description" class="text-muted">
                        {{ $t('system.missing_input') }}
                      </p>
                    </div>

                    <br />
                    <label for="">{{ $t('maintenance.questions.create.add_description_label') }}</label>
                    <div v-if="genericControl.add_description"  class="section-container">
                      {{ $t('maintenance.questions.type.' + $options.filters.slugify(genericControl.add_description)) }}
                    </div>
                    <div v-if="!genericControl.add_description"  class="section-container">
                      {{ $t('maintenance.questions.type.optional') }}
                    </div>
                    <br />
                    <label for="">{{ $t('maintenance.questions.create.add_attachments_label') }}</label>
                    <div v-if="genericControl.add_attachments"  class="section-container">
                      {{ $t('maintenance.questions.type.' + $options.filters.slugify(genericControl.add_attachments)) }}
                    </div>
                    <div v-if="!genericControl.add_description"  class="section-container">
                      {{ $t('maintenance.questions.type.optional') }}
                    </div>

                    <br />
                    <label v-if="isGranted([permissions.GENERIC_CONTROL_EDIT, permissions.GENERIC_CONTROL_EDIT_OWN], genericControl) && (!getClient.parent || (getClient.parent && genericControl.partition))"
                           class="btn btn-secondary cursor-pointer float-right"
                           qid="create-p-file-button"
                    >
                      <input type="file"
                             name="fileupload"
                             value="fileupload"
                             multiple
                             id="file"
                             @change="onFileChange"
                             @click="resetFile"
                      >
                      <span>
                        <font-awesome-icon icon="cloud-upload-alt" />
                        {{ $t('system.upload') }}
                      </span>
                    </label>
                    <label for="">
                      {{ $t('attachments.label') }}
                      <br>
                      <span>({{ $t('attachments.file_size_label') }})</span>
                    </label>
                    <b-alert v-if="error.length"
                             show
                             variant="danger"
                             qid="attachments-p-error"
                    >
                      {{ error }}
                    </b-alert>
                    <p v-for="file in genericControl.media" class="section-container">
                      <span class="float-right text-muted cursor-pointer"
                            @click="openDeleteAttachmentModal(file)"
                            qid="remove-media-item"
                            v-if="isGranted([permissions.GENERIC_CONTROL_EDIT, permissions.GENERIC_CONTROL_EDIT_OWN], genericControl) && (!getClient.parent || (getClient.parent && genericControl.partition))"
                      >
                        <font-awesome-icon icon="trash-alt" />
                      </span>
                      <span class="mr-3 float-right text-muted cursor-pointer"
                            @click="downloadMedia(file)"
                            qid="download-media"
                      >
                        <font-awesome-icon icon="cloud-download-alt" />
                      </span>
                      <a @click="downloadMedia(file)" qid="create-p-media-item">
                        {{ file.file_name }}
                      </a>
                    </p>
                    <p v-if="genericControl.media && !genericControl.media.length" class="text-muted section-container">
                      {{ $t('system.missing_input') }}
                    </p>

                    <br />
                    <span class="btn btn-link cursor-pointer float-right"
                          @click="showInput = true"
                          qid="add-another-link"
                          v-if="isGranted([permissions.GENERIC_CONTROL_EDIT, permissions.GENERIC_CONTROL_EDIT_OWN], genericControl) && (!getClient.parent || (getClient.parent && genericControl.partition))"
                    >
                      <font-awesome-icon icon="plus"/> {{ $t('system.add_link') }}
                    </span>
                    <label for="">
                      {{ $t('links.label') }}
                    </label>
                    <div v-if="genericControl.links">
                      <p v-for="link in genericControl.links" class="section-container">
                        <span class="float-right text-muted cursor-pointer"
                              @click="openDeleteLinkModal(link)"
                              qid="view-p-remove-link"
                              v-if="isGranted([permissions.GENERIC_CONTROL_EDIT, permissions.GENERIC_CONTROL_EDIT_OWN], genericControl) && (!getClient.parent || (getClient.parent && genericControl.partition))"
                        >
                          <font-awesome-icon icon="trash-alt" />
                        </span>
                        <a :href="link.url" target="_blank" qid="create-p-link-item">{{ link.name ? link.name : link.url }}</a>
                      </p>
                    </div>
                    <p v-if="genericControl.links && !genericControl.links.length" class="text-muted section-container">
                      {{ $t('system.missing_input') }}
                    </p>
                    <div v-if="showInput">
                      <label class="mt-2">{{ $t('system.link') }}</label>
                      <b-form-input
                              type="text"
                              aria-describedby="inputGroup"
                              v-model="inputLink"
                              :placeholder="$t('maintenance.third_parties.create.links_placeholder')"
                              qid="create-p-links"
                      ></b-form-input>
                      <label class="mt-2">{{ $t('table.name') }}</label>
                      <b-form-input
                              type="text"
                              aria-describedby="inputGroup"
                              v-model="inputLinkName"
                              :placeholder="inputLink ? inputLink : $t('table.name')"
                              qid="create-p-links"
                      ></b-form-input>
                      <div class="text-right mt-2">
                        <span class="btn btn-success cursor-pointer mr-0"
                              id="inputGroup"
                              @click="handleLink"
                              qid="handle-link-save"
                        >
                          {{ $t('system.save') }}
                        </span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
          <br />
          <PoliciesTable :object="genericControl" @updated="updateGenericControl" v-if="getClient.policyModule"></PoliciesTable>

        </b-tab>

        <!-- Framework controls tab -->
        <b-tab :title="$t('generic_controls.tab.framework_controls')" class="mt-3 threats-tab" @click="frameworkControlsTabClicked">
          <div v-if="showFrameworkControlsTab">
            <FrameworkControlsTable :object="genericControl" @updated="updateGenericControl"></FrameworkControlsTable>
          </div>
        </b-tab>

        <!-- Mitigations tab -->
        <b-tab :title="$t('generic_controls.tab.mitigations')" class="mt-3 threats-tab" @click="mitigationsTabClicked">
          <div v-if="showMitigationsTab">
            <MitigationsRiskScenariosTable
                :object="genericControl"
                @updated="updateGenericControl"
                v-if="genericControl.type == 'Control'"
            ></MitigationsRiskScenariosTable>
            <MitigationsThreatsTable
                :object="genericControl"
                @updated="updateGenericControl"
                v-if="genericControl.type == 'Security measure'"
            ></MitigationsThreatsTable>
            <MitigationsVulnerabilitiesTable
                :object="genericControl"
                @updated="updateGenericControl"
                v-if="genericControl.type == 'Security measure'"
            ></MitigationsVulnerabilitiesTable>
          </div>
        </b-tab>

        <!-- Measures tab -->
        <b-tab :title="$t('generic_controls.tab.measures')" class="mt-3 measures-tab" @click="measuresTabClicked">
          <div v-if="showMeasuresTab">
            <MeasuresTable :object="genericControl" @updated="updateGenericControl"></MeasuresTable>
          </div>
        </b-tab>

        <!-- Projects tab -->
        <b-tab :title="$t('generic_controls.tab.projects')" class="mt-3 vulnerabilities-tab" @click="projectTemplatesTabClicked">
          <div v-if="showProjectTemplatesTab">
            <ProjectTemplatesTable :object="genericControl" @updated="updateGenericControl"></ProjectTemplatesTable>
          </div>
        </b-tab>

        <!-- Validations tab -->
        <b-tab :title="$t('generic_controls.tab.validations')"
               class="mt-3"
               @click="validationTabClicked"
        >
          <!-- Validation section component -->
          <Validations
              :object="genericControl"
              type="generic_control"
              :checklist="genericControl.validationOptions"
              @updated="updateValidation"
              :permission="['GENERIC_CONTROL_VALIDATE', 'GENERIC_CONTROL_VALIDATE_OWN']"
              createButton="secondary"
              activeGuide="generic_controls_update_validation"
          ></Validations>
        </b-tab>

        <!-- Generic controls history tab -->
        <b-tab :title="$t('generic_controls.tab.history')" class="mt-3" @click="historyTabClicked">
          <div v-if="showHistoryTab">
            <GenericControlsHistory :object="genericControl"></GenericControlsHistory>
          </div>
        </b-tab>

      </b-tabs>


      <!-- Delete generic control template -->
      <b-modal ref="delete-genericControl" hide-footer>
        <div class="d-block text-center">
          <h4 qid="delete-s-title">
            {{ $t('generic_controls.delete.title') }}
          </h4>
        </div>
        <h5 class="text-center">
          {{ $t('generic_controls.delete.description') }} {{ itemToDelete.name }}
        </h5>
        <h5 class="text-center">
          {{ $t('generic_controls.delete.task_notice') }}
        </h5>
        <h5 class="text-center">
          {{ $t('generic_controls.delete.are_you_sure') }}
        </h5>
        <div class="text-center">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideConfirmDeleteModal"
                    qid="delete-s-cancel-button"
          >
            {{ $t('generic_controls.delete.cancel') }}
          </b-button>
          <b-button @click="confirmDelete(itemToDelete)"
                    class="mt-3 ml-3"
                    variant="success"
                    qid="delete-s-submit-button"
                    v-if="isGranted([permissions.GENERIC_CONTROL_DELETE, permissions.GENERIC_CONTROL_DELETE_OWN], genericControl)"
          >
            {{ $t('generic_controls.delete.submit') }}
          </b-button>
        </div>
      </b-modal>

      <ConfirmRemoveAttachment
          :object="selectedFile"
          :show="showDeleteAttachmentModal"
          type="file"
          @closeConfirmModal="closeDeleteAttachmentModal"
      ></ConfirmRemoveAttachment>

      <ConfirmRemoveAttachment
          :object="selectedLink"
          :show="showDeleteLinkModal"
          type="link"
          @closeConfirmModal="closeDeleteLinkModal"
      ></ConfirmRemoveAttachment>

      <ShowMoreModal
          :items="selectedItem.tags_translated"
          :show="showTagsModal"
          @closeViewModal="closeTagsModal"
          type="tags"
      ></ShowMoreModal>
    </div>


    <div v-if="show404">
      <page404></page404>
    </div>

    <div v-if="show403">
      <page403></page403>
    </div>

  </div>
</template>

<script>
import piincHttp from '@/util/configureAxios.js'
import {config} from '@/config.js'
import GenericControlsEditForm from '@/views/GenericControls/GenericControlsEditForm.vue';
import Avatar from '@/components/Avatar.vue';
import GenericControlsHistory from '@/views/GenericControls/GenericControlHistory.vue';
import ConfirmRemoveAttachment from '@/components/ConfirmRemoveAttachment.vue';
import Validations from '@/views/Validations/Validations.vue';
import PoliciesTable from '@/views/GenericControls/PoliciesTable.vue';
import ProjectTemplatesTable from '@/views/GenericControls/ProjectTemplatesTable.vue';
import MitigationsRiskScenariosTable from '@/views/GenericControls/MitigationsRiskScenariosTable.vue';
import MitigationsThreatsTable from '@/views/GenericControls/MitigationsThreatsTable.vue';
import MitigationsVulnerabilitiesTable from '@/views/GenericControls/MitigationsVulnerabilitiesTable.vue';
import FrameworkControlsTable from '@/views/GenericControls/FrameworkControlsTable.vue';
import ShowMoreModal from '@/components/ShowMoreModal.vue';
import MeasuresTable from '@/views/GenericControls/MeasuresTable.vue';
import GuideButton from '@/components/guide/GuideButton.vue';
import page404 from '@/components/404block.vue';
import page403 from '@/components/403block.vue';


export default {
  name: 'GenericControlView',
  data: function() {
    return {
      breadcrumbItems: [
        {
          text: this.$t('dashboard.title'),
          to: '/'
        },
        {
          text: this.$t('generic_controls.title'),
          to: '/generic-controls'
        }
      ],

      form: {
        description: '',
        passed_description: '',
        failed_description: ''
      },
      showForm: false,
      addDescription: [],
      addAttachments: [],

      genericControl: {},
      descriptionLength: config.descriptionLength,
      showFullDescription: false,
      showFullPassedDescription: false,
      showFullFailedDescription: false,
      edit: this.$route.params.edit,
      showInput: false,

      itemToDelete: {},
      users: [],
      error: '',
      inputLink: '',
      inputLinkName: '',
      selectedFiles: [],
      selectedFile: {},
      selectedLink: {},
      showDeleteAttachmentModal: false,
      showDeleteLinkModal: false,
      showDescriptionInput: false,
      genericControlEditDescription: '',

      allowTypes: [
        { name: this.$t('maintenance.questions.type.no'), value: 'No' },
        { name: this.$t('maintenance.questions.type.optional'), value: 'Optional' },
        { name: this.$t('maintenance.questions.type.mandatory'), value: 'Mandatory' },
      ],

      // Tabs
      showValidationTab: false,
      showFrameworkControlsTab: false,
      showMitigationsTab: false,
      showProjectTemplatesTab: false,
      showHistoryTab: false,
      showBasicInfoTab: true,
      showMeasuresTab: false,

      buttonDisabled: false,
      tabIndex: 0,
      myGuides: [],
      selectedItem: {},
      showTagsModal: false,
      show404: false,
      show403: false,
    }
  },
  components: {
    GenericControlsEditForm,
    Avatar,
    GenericControlsHistory,
    ConfirmRemoveAttachment,
    Validations,
    PoliciesTable,
    ProjectTemplatesTable,
    MitigationsRiskScenariosTable,
    MitigationsThreatsTable,
    MitigationsVulnerabilitiesTable,
    FrameworkControlsTable,
    ShowMoreModal,
    MeasuresTable,
    GuideButton,
    page403,
    page404
  },
  computed: {
    getClient() {
      return this.$store.state.client
    },
    getFiles() {
      return this.selectedFiles
    }
  },
  watch: {
    // Watch when client changes in store and trigger api reload
    getClient: function (newValue, oldValue) {
      if (newValue && oldValue && newValue.slug && oldValue.slug && newValue.slug !== oldValue.slug) {
        this.goToList()
      } else {
        this.loadContent(true);
      }
    }
  },
  methods: {
    goToList() {
      this.$router.push('/generic-controls')
    },
    openTagsModal(object) {
      this.selectedItem = object
      this.showTagsModal = true
    },
    closeTagsModal() {
      this.selectedItem = {}
      this.showTagsModal = false
    },
    getReducedTags(tags) {
      let reducedTags = []
      if (tags.length > 3) {
        reducedTags = _.cloneDeep(tags);
        reducedTags = reducedTags.slice(0, 3);
      } else {
        reducedTags = tags;
      }
      return reducedTags;
    },
    getTask(code) {
      let existingTask = _.find(this.myTasks, function(object) {
        return object.code == code
      });
      return existingTask
    },
    resetFile() {
      let imagefile = document.querySelector('#file');
      imagefile.value = '';
    },
    showFormFunction() {
      this.setDefaultForm(this.genericControl)
      this.showForm = true
    },
    cancelForm() {
      this.error = ''
      this.showForm = false
    },
    setDefaultForm(object) {
      let self = this;

      this.addDescription = []
      if (object.add_description) {
        this.addDescription = _.find(this.allowTypes, function(item) {
          return item.value === object.add_description;
        });
      }

      this.addAttachments = []
      if (object.add_attachments) {
        this.addAttachments = _.find(this.allowTypes, function(item) {
          return item.value === object.add_attachments;
        });
      }

      this.form.description = this.genericControl.description;
      this.form.passed_description = this.genericControl.passed_description;
      this.form.failed_description = this.genericControl.failed_description;
    },
    formSubmit(evt) {
      // Keep the reference in a variable
      // to use it inside function called when promise returned
      let self = this;
      evt.preventDefault();

      if (this.addDescription && this.addDescription.value) {
        this.form.add_description = this.addDescription.value
      }

      if (this.addAttachments && this.addAttachments.value) {
        this.form.add_attachments = this.addAttachments.value
      }

      if (this.addDescription && this.addDescription.value && this.addAttachments && this.addAttachments.value) {
        piincHttp.put('generic-controls/' + this.$route.params.slug, this.form).then(function(response) {
          self.genericControl = response.data
          self.error = ''
          self.showForm = false;
          self.$toastr('success', self.$t('generic_controls.update.success'))
        }, function(error) {
          if (error.status !== 200) {
            self.error = error.data.message;
          }
        })
      } else {
        this.error = '';
        if (!this.addDescription || (this.addDescription && !this.addDescription.value)) {
          this.error += this.$t('maintenance.questions.create.add_description_error_message')
        }
        if (!this.addAttachments || (this.addAttachments && !this.addAttachments.value)) {
          this.error += this.$t('maintenance.questions.create.add_attachments_error_message')
        }
      }
    },
    loadContent(breadcrumbPush) {
      let self = this;
      piincHttp.get('generic-controls/' + this.$route.params.slug, { params:
        {
          client: this.getClient.slug,
        }
      }).then(function(response) {
        self.genericControl = response.data;
        if (breadcrumbPush) {
          // Push Third party name in breadcrumb array
          self.breadcrumbItems.push({text: self.genericControl.name, active: true});
        }
      }, function(error) {
        if (error.status == 404) {
          self.show404 = true
        }
        if (error.status == 403) {
          self.show403 = true
        }
      });
    },
    updateGenericControl() {
      this.loadContent();
    },
    historyTabClicked() {
      this.showHistoryTab = true;
      this.showFrameworkControlsTab = false;
      this.showMitigationsTab = false;
      this.showProjectTemplatesTab = false;
      this.showValidationTab = false;
      this.showBasicInfoTab = false;
      this.showMeasuresTab = false;
    },
    basicTabClicked() {
      this.showBasicInfoTab = true;
      this.showFrameworkControlsTab = false;
      this.showMitigationsTab = false;
      this.showProjectTemplatesTab = false;
      this.showValidationTab = false;
      this.showHistoryTab = false;
      this.showMeasuresTab = false;
    },
    frameworkControlsTabClicked() {
      this.showFrameworkControlsTab = true;
      this.showMitigationsTab = false;
      this.showProjectTemplatesTab = false;
      this.showHistoryTab = false;
      this.showValidationTab = false;
      this.showBasicInfoTab = false;
      this.showMeasuresTab = false;
    },
    mitigationsTabClicked() {
      this.showMitigationsTab = true;
      this.showProjectTemplatesTab = false;
      this.showFrameworkControlsTab = false;
      this.showHistoryTab = false;
      this.showValidationTab = false;
      this.showBasicInfoTab = false;
      this.showMeasuresTab = false;
    },
    projectTemplatesTabClicked() {
      this.showProjectTemplatesTab = true;
      this.showFrameworkControlsTab = false;
      this.showMitigationsTab = false;
      this.showHistoryTab = false;
      this.showValidationTab = false;
      this.showBasicInfoTab = false;
      this.showMeasuresTab = false;
    },
    measuresTabClicked() {
      this.showMeasuresTab = true;
      this.showProjectTemplatesTab = false;
      this.showFrameworkControlsTab = false;
      this.showMitigationsTab = false;
      this.showHistoryTab = false;
      this.showValidationTab = false;
      this.showBasicInfoTab = false;
    },
    validationTabClicked() {
      this.showValidationTab = true;
      this.showHistoryTab = false;
      this.showFrameworkControlsTab = false;
      this.showMitigationsTab = false;
      this.showProjectTemplatesTab = false;
      this.showBasicInfoTab = false;
      this.showMeasuresTab = false;
    },
    handleCloseViewPModal () {
      this.loadPolicies();
      this.viewPolicyModal = false;
      this.selectedPolicy = []
    },
    updateValidation(validation) {
      // Event emmited from validation component, update active validation
      this.genericControl.validation = validation
    },
    showDescriptionInputFunction() {
      this.showDescriptionInput = true;
      this.genericControlEditDescription = this.genericControl.description;
    },
    formDescriptionSubmit() {
      let self = this;
      piincHttp.put('generic-controls/' + this.$route.params.slug, {
        description: this.genericControlEditDescription
      }).then(function(response) {
        self.genericControl = response.data;
        self.showDescriptionInput = false;
        self.$toastr('success', self.$t('generic_controls.update.success'))
      }, function(error) {
        if (error.status !== 200) {
          self.error = error.data.message;
        }
      })
    },
    cancelDescriptionForm() {
      this.showDescriptionInput = false;
      this.genericControlEditDescription = this.genericControl.description;
    },
    openViewPolicyModal(object) {
      this.selectedPolicy = object
      this.viewPolicyModal = true
    },
    reloadContent(object) {
      if (this.tabIndex == 1) {
        this.tabIndex = 0
      }
      // If slug is not changed, just reload content
      if (object.slug == this.genericControl.slug) {
        // Remove last item in breadcrumbs, because it will be added again on load content
        this.breadcrumbItems.pop();
        this.loadContent(true);
      } else {
        this.$store.commit('storeUpdateTasks', true);
        // If slug changed, reload state with new param
        this.$router.push({ name: 'generic_controls.view', params: { slug: object.slug }})
      }
    },
    getUserAvatar(user){
      if (user && user.avatar) {
        return user.avatar;
      } else {
        return require('@/assets/avatar.png');
      }
    },
    showConfirmDeleteModal(object) {
      this.itemToDelete = object
      this.$refs['delete-genericControl'].show()
    },
    hideConfirmDeleteModal() {
      this.itemToDelete = {}
      this.$refs['delete-genericControl'].hide()
    },
    confirmDelete(object) {
      let self = this;
      piincHttp.delete('generic-controls/' + object.slug).then(function() {
        self.hideConfirmDeleteModal();
        self.goToList();
        // Trigger toastr message after modal close
        self.$toastr('success', self.$t('generic_controls.delete.success'))
      }, function() {});
    },
    handleLink() {
      let self = this;
      let formData = new FormData();

      if (!this.inputLink.startsWith('http')) {
        this.inputLink = 'http://' + this.inputLink
      }

      formData.append("url", this.inputLink);
      formData.append("name", this.inputLinkName);
      formData.append("model_type", this.genericControl.who_am_i);
      formData.append("model_id", this.genericControl.id);

      piincHttp.post('links', formData).then(function() {
        // Remove last item in breadcrumbs, because it will be added again on load content
        self.breadcrumbItems.pop();
        self.$toastr('success', self.$t('maintenance.third_parties.link_add_success'))
        self.loadContent()
        self.inputLink = ''
        self.inputLinkName = ''
        self.showInput = false
      }, function() {})
    },
    downloadMedia(object){
      piincHttp.get('documents/' + object.id + '/download', {
        responseType: 'blob',
        params:
          {
            client: this.getClient.slug
          }
      }).then(function(response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = object.file_name;

        link.click();
        link.remove()
        window.URL.revokeObjectURL(url);
      }, function() {});
    },
    closeDeleteAttachmentModal () {
      this.selectedFile = {}
      this.showDeleteAttachmentModal = false;
      this.loadContent();
    },
    closeDeleteLinkModal () {
      this.selectedLink = {}
      this.showDeleteLinkModal = false;
      this.loadContent();
    },
    openDeleteAttachmentModal(object) {
      this.selectedFile = object
      this.showDeleteAttachmentModal = true
    },
    openDeleteLinkModal(object) {
      this.selectedLink = object
      this.showDeleteLinkModal = true
    },
    onFileChange(file) {
      let files = file.target.files;
      let self = this;
      let formData = new FormData();
      let sizeError = false;

      for (var i = 0; i < files.length; i++) {
        // Equal to 10 MB
        if (files[i].size > 10485760) {
          sizeError = true;
        }
        formData.append('document[]', files[i]);
      }

      formData.append("name", "documents");
      formData.append("model_type", this.genericControl.who_am_i);
      formData.append("model_id", this.genericControl.id);

      if (sizeError) {
        this.error = this.$t('system.file_size')
      } else {
        piincHttp.post('documents', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function() {
          // Remove last item in breadcrumbs, because it will be added again on load content
          self.breadcrumbItems.pop();
          self.$toastr('success', self.$t('maintenance.third_parties.media_add_success'))
          self.error = ''
          self.loadContent()
        }, function(error) {
          self.error = error.data.message;
          if (error.data.errors['document.0']) {
            self.error += ' ' + error.data.errors['document.0'][0]
          }
        })
      }
    },
    getGuide(code) {
      let existingGuide = _.find(this.myGuides, function(object) {
        return object.scope == code && object.type == 'Generic control' && object.enabled
      });
      return existingGuide
    },
    loadGuides(code) {
      let self = this;
      let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

      piincHttp.get('guides', { params:
        {
          per_page: 1,
          language: 'en',
          type: 'Generic control',
          scope: code,
          client: this.getClient.slug
        }
      }).then(function(response) {
        if (response.data.items.length) {
          self.myGuides.push(response.data.items[0])
        }
      }, function() {});
    },
    updateActivity() {
      this.loadContent();
    }
  },
  created () {
    let self = this;
    if (this.$route.params.tabIndex && this.$route.params.validation) {
      setTimeout(function(){
        self.tabIndex = self.$route.params.tabIndex
      }, 500);
      setTimeout(function(){
        var element = document.getElementsByClassName(self.$route.params.validation);
        element[0].scrollIntoView(true);
        window.scrollBy(0, -100);
        element[0].classList.add("highlight-validation");
      }, 2000);
    }
    if (this.getClient && this.getClient.slug) {
      this.loadContent(true);
    }
    this.loadGuides('generic_controls_update_basic_info');
    this.loadGuides('generic_controls_update_description');
  }
}
</script>
