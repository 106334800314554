<template>
  <div>
    <!-- Threats table -->
    <div v-if="isGranted([permissions.GENERIC_CONTROL_THREAT_VIEW, permissions.GENERIC_CONTROL_THREAT_VIEW_OWN], object)"
         class="card"
         qid="threats-section"
         id="update_threats"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <h5 qid="threat-table-title" class="mb-2">
              {{ $t('generic_controls.threats.title') }}
            </h5>
            <span class="cursor-pointer task-label-open mr-2"
                  v-if="getTask('update_threats')"
                  @click="openToolbar(getTask('update_threats'))"
            >
              <font-awesome-icon icon="info-circle" class="text-primary"/>
              {{ $t('tasks.open_label') }}
            </span>
            <span v-if="getGuide('generic_controls_update_threat')">
              <GuideButton :guide="getGuide('generic_controls_update_threat')"></GuideButton>
            </span>
          </div>
          <div class="col-sm-6 text-right">
            <button class="btn btn-success"
                    qid="threat-create-button"
                    @click="showCreatePolicyModal"
                    v-if="isGranted([permissions.GENERIC_CONTROL_THREAT_CREATE, permissions.GENERIC_CONTROL_THREAT_CREATE_OWN], object) && (!getClient.parent || (getClient.parent && object.partition))"
            >
              <font-awesome-icon icon="plus"/> {{ $t('generic_controls.threats.create_button') }}
            </button>
          </div>
        </div>

        <table class="table table-responsive-md table-list-secondary">
          <thead>
            <tr>
              <th scope="col"
                  class="cursor-pointer"
                  @click="selectSort('name')"
                  qid="sort-s-name"
              >
                {{ $t('generic_controls.threats.name') }}
                <span class="ml-2">
                  <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'name'"/>
                  <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'name'"/>
                  <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'name'"/>
                </span>
              </th>
              <th scope="col">
                {{ $t('generic_controls.threats.actors') }}
              </th>
              <th scope="col"
                  class="cursor-pointer"
                  @click="selectSort('updated_at')"
                  qid="sort-s-name"
              >
                {{ $t('system.last_updated') }}
                <span class="ml-2">
                  <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'updated_at'"/>
                  <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'updated_at'"/>
                  <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'updated_at'"/>
                </span>
              </th>
              <th width="230"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in threatTableItems">
              <td scope="row">
                <button v-if="item.threat"
                        @click="showViewModal(item)"
                        qid="threat-name-link"
                        class="btn btn-link text-left"
                >
                  {{ item.threat.name }}
                </button>
              </td>
              <td>
                <span class="mr-1 text-normal" v-for="actor in item.threat.actors" qid="actors-list">
                  <span>
                    {{ actor.name }}
                  </span>
                </span>
              </td>
              <td>
                <span class="text-normal">
                  {{ item.threat.updated_at | formatDate }}
                </span>
              </td>
              <td>
                <button class="btn btn-view"
                        qid="view-links-button"
                        @click="showViewLinksModal(item)"
                >
                  {{ $t('generic_controls.threats.view_links_button') }}
                </button>
                <button v-if="item.threat"
                        @click="showViewModal(item)"
                        qid="threat-name-link"
                        class="btn btn-view"
                >
                  <font-awesome-icon icon="eye"/>
                </button>
                <button v-if="isGranted([permissions.GENERIC_CONTROL_THREAT_DELETE, permissions.GENERIC_CONTROL_THREAT_DELETE_OWN], object) && (!getClient.parent || (getClient.parent && object.partition))"
                        class="btn btn-delete"
                        qid="delete-threat-modal"
                        @click="showPolicyConfirmDeleteModal(item)"
                >
                  <font-awesome-icon icon="trash-alt"/>
                </button>
              </td>
            </tr>
            <tr v-if="!threatLoading && !threatTableItems.length" qid="no-results-row" class="text-center">
              <td colspan="10">
                <h5 class="mt-3">
                  {{ $t('system.no_results') }}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body">
        <div class="mt-3 pl-2 pagination row">
          <div class="col-md-6">
            <div class="row">
              <div class="float-left">
                <span class="text-muted pagination-counter">
                  {{ $t('pagination.entries_per_page') }}:
                </span>
              </div>
              <div class="float-left">
                <multiselect
                    v-model="perPage"
                    :options="perPageOptions"
                    track-by="value"
                    label="value"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-org-form-organization-level"
                    @select="perPageChanged"
                    :placeholder="selectedPerPage"
                    @remove="perPageRemoved"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="selectedPerPage"
                aria-controls="my-table"
                @change="pageChanged"
                limit="1"
            >
              <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
              <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
              <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
              <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
              <div slot="ellipsis-text">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
              <span class="text-muted" slot="page" slot-scope="{ page, active }">
                <span v-if="active">{{getPerPage}}</span>
              </span>
            </b-pagination>
          </div>
        </div>
      </div>
    </div>



    <!-- Delete threat modal template -->
    <b-modal ref="delete-threat" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-lb-title">
          {{ $t('generic_controls.threats.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        {{ $t('generic_controls.threats.delete.description') }}
        <span v-if="itemToDelete.threat">{{ itemToDelete.threat.name }}</span>
      </h5>
      <h5 class="text-center">
        {{ $t('generic_controls.threats.delete.are_you_sure') }}
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hidePolicyConfirmDeleteModal"
                  qid="delete-threat-cancel-button"
        >
          {{ $t('generic_controls.threats.delete.cancel') }}
        </b-button>
        <b-button @click="confirmDeletePolicy(itemToDelete)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-threat-submit-button"
                  v-if="isGranted([permissions.GENERIC_CONTROL_THREAT_DELETE, permissions.GENERIC_CONTROL_THREAT_DELETE_OWN], object)"
        >
          {{ $t('generic_controls.threats.delete.submit') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Add new threat -->
    <b-modal ref="add-threat" hide-footer @hide="hideCreatePolicyModal">
      <div class="d-block text-center">
        <h4 qid="add-io-title">
          {{ $t('generic_controls.threats.create.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-io-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="threatFormSubmit" qid="create-threat-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-threat-pg-label">
              {{ $t('generic_controls.threats.create.threat_label') }}
            </label>
            <multiselect
                v-model="selectedThreat"
                :options="threatItems"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('generic_controls.threats.create.threat_placeholder')"
                label="name"
                @search-change="loadThreatItems"
                track-by="slug"
                qid="create-lb-form-pg"
            ></multiselect>
          </b-form-group>

        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideCreatePolicyModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('generic_controls.threats.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-p-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('generic_controls.threats.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>


    <!-- View links modal -->
    <b-modal ref="view-links" hide-footer @hide="hideViewLinksModal" size="lg">
      <div class="d-block text-center">
        <h4 qid="add-io-title">
          {{ $t('generic_controls.threats.view.links_title') }}
        </h4>
        <h4 v-if="linksItem && linksItem.threat">
          '{{ linksItem.threat.name }}'
        </h4>
      </div>
      <b-tabs class="mt-3">
        <b-tab :title="$t('risk_scenarios.title')" active>
          <div class="modal-tabs-scrollable">
            <div v-for="object in linksItems" :key="object.id">
              <h5 class="mt-4">
                <router-link
                    :to="{ name: 'risk_scenarios.view', params: { slug: object.risk_scenario.slug }}"
                    qid="s-view-page-link"
                >
                  {{ object.risk_scenario.name }}
                </router-link>
              </h5>
              <hr>
            </div>
            <div class="text-center" @click="loadLinksItemsControl()" v-if="showMore && !loading">
              <a>{{ $t('system.show_more') }}</a>
            </div>
            <div class="text-center" v-if="linksItems && !linksItems.length && !loading">
              <a>{{ $t('system.no_results') }}</a>
            </div>
            <div class="text-center" v-if="loading">
                <font-awesome-icon icon="spinner" size="lg" class="fa-spin mr-2"/>
                {{ $t('system.loading') }}
            </div>
          </div>
        </b-tab>
        <div>
          <b-button class="mt-4 mb-4 float-right"
                    variant="success"
                    @click="hideViewLinksModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('processes.policies.view.close') }}
          </b-button>
        </div>
      </b-tabs>

    </b-modal>


    <!-- View threat modal -->
    <b-modal ref="view-modal" hide-footer @hide="hideViewModal" size="md">
      <div class="d-block text-center mb-4">
        <h4 qid="add-io-title">
          {{ $t('generic_controls.threats.view.title') }}
        </h4>
        <h4 v-if="linksItem && linksItem.threat">
          '{{ linksItem.threat.name }}'
        </h4>
      </div>
      <b-form-group v-if="linksItem && linksItem.threat">
        <label qid="create-p-users-label">
          {{ $t('generic_controls.threats.actors') }}
        </label>
        <br>
        <span class="mr-1" v-for="actor in linksItem.threat.actors" qid="p-tags-list">
          <span class="badge badge-info mb-1">
            {{ actor.name }}
          </span>
        </span>
      </b-form-group>
      <b-form-group v-if="linksItem && linksItem.threat">
        <label qid="create-p-users-label">
          {{ $t('generic_controls.threats.description') }}
        </label>
        <div v-if="linksItem.threat.description && linksItem.threat.description.length > 300">
          <p v-if="showFullDescription" class="text-formatted">
            {{ linksItem.threat.description }}
          </p>
          <p v-if="!showFullDescription" class="text-formatted">
            {{ linksItem.threat.description | truncate(300) }}
          </p>
          <div class="text-center mt-3" @click="showFullDescription=!showFullDescription">
            <span class="cursor-pointer" v-if="!showFullDescription">
              <span class="mr-2">
                {{ $t('system.view_more') }}
              </span>
              <font-awesome-icon icon="chevron-down"/>
            </span>
            <span class="cursor-pointer" v-if="showFullDescription">
              <span class="mr-2">
                {{ $t('system.view_less') }}
              </span>
              <font-awesome-icon icon="chevron-up"/>
            </span>
          </div>
        </div>
        <p v-if="linksItem.threat.description && linksItem.threat.description.length <= 300"
           class="text-formatted"
        >
          {{ linksItem.threat.description }}
        </p>
      </b-form-group>
      <div>
        <b-button class="mt-4 mb-4 float-right"
                  variant="success"
                  @click="hideViewModal"
                  qid="create-p-cancel-button"
        >
          {{ $t('processes.policies.view.close') }}
        </b-button>
      </div>

    </b-modal>

  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import GuideButton from '@/components/guide/GuideButton.vue';


  export default {
    name: 'MitigationsThreatsTable',
    data: function() {
      return {
        error: '',
        loadingItems: false,
        showFullDescription: false,
        itemToDelete: {},
        buttonDisabled: false,
        threatTableItems: [],
        threatLoading: true,
        threatValidationShow: false,
        showPolicyDescription: false,
        threatItems: [],
        selectedThreat: [],
        viewPolicyModal: false,
        linksItems: [],
        linksItem: {},
        page: 1,
        showMore: true,
        loading: true,
        selectedSort: '',
        selectedDirection: '',

        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],
        perPage: '',
        selectedPerPage: "10",
        currentPage: 1,
        totalRows: 0,
        myGuides: []
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      },
      tasks: {
        type: Array
      }
    },
    components: {
      Avatar,
      GuideButton
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      rows() {
        // Use computed value for rows because of dynamic update
        return this.totalRows
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getPerPage() {
        let page = this.currentPage;
        let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
        let selectedOf = this.totalRows;

        if(selectedTo > selectedOf){
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " of " + selectedOf
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadThreatItems();
      }
    },
    methods: {
      getTask(code) {
        let existingTask = _.find(this.tasks, function(object) {
          return object.code == code
        });
        return existingTask
      },
      openToolbar(object) {
        this.$store.commit('storeTaskToolbarState', true);
        this.$store.commit('storeActiveTask', object);
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Generic control' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Generic control',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
          }
        }, function() {});
      },
      resetFields() {
        this.selectedDropdownOption = null
      },
      selectSort(sort) {
        // If old and new sort are same, switch just direction
        if (sort === this.selectedSort) {
          this.selectedDirection = this.selectedDirection === 'desc' ? 'asc' : 'desc';
        } else {
          // If different sort, set sort and set direction to default
          this.selectedDirection = 'desc'
          this.selectedSort = sort;
        }
        this.loadThreats();
      },
      showViewLinksModal(object) {
        this.page = 1;
        this.showMore = true;
        this.linksItems = []
        this.linksItem = object;
        this.loadLinksItemsControl()
        this.$refs['view-links'].show()
      },
      hideViewLinksModal() {
        this.linksItem = []
        this.$refs['view-links'].hide()
      },
      showViewModal(object) {
        this.linksItem = object;
        this.$refs['view-modal'].show()
      },
      hideViewModal() {
        this.linksItem = []
        this.$refs['view-modal'].hide()
      },
      showCreatePolicyModal() {
        this.loadThreatItems();
        this.$refs['add-threat'].show()
      },
      hideCreatePolicyModal() {
        this.error = ''
        this.selectedThreat = [];
        this.showPolicyDescription = false;
        this.$refs['add-threat'].hide()
      },
      showPolicyConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.$refs['delete-threat'].show()
      },
      hidePolicyConfirmDeleteModal() {
        this.itemToDelete = {}
        this.$refs['delete-threat'].hide()
      },
      pageChanged(page) {
        // Load Third parties when page changed listener is triggered
        this.loadThreats(page)
      },
      perPageChanged(perPage) {
        let self = this;

        self.selectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.selectedPerPage);
        // Load Third parties when page changed listener is triggered
        this.loadThreats(1, perPage.value)
      },
      perPageRemoved() {
        let self = this;
        // Load Third parties when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value
        this.loadThreats(1, self.selectedPerPage)

      },
      loadThreats(page, perPage) {

        if (this.getStorePerPage) {
          this.selectedPerPage = this.getStorePerPage;
        }

        // If page is not defined, set page to default 1
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.selectedPerPage;
        let self = this;
        piincHttp.get('generic-controls/' + this.$route.params.slug + '/threats', { params:
          {
            page: pageNumber,
            per_page: perPageNumber,
            sort: this.selectedSort !== '' ? this.selectedSort : undefined,
            order: this.selectedDirection !== '' ? this.selectedDirection : undefined,
            client: this.getClient.slug,
          }
        }).then(function(response) {
          self.threatTableItems = response.data.items;
          self.totalRows = response.data.total_count;
          self.threatLoading = false;
        }, function() {});
      },
      confirmDeletePolicy(object) {
        let self = this;
        piincHttp.delete('generic-controls/' + this.object.slug + '/threats/' + object.id).then(function() {
          self.hidePolicyConfirmDeleteModal();
          self.loadThreats(self.currentPage);
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('generic_controls.threats.delete.success'))
        }, function() {});
      },
      threatFormSubmit(evt) {
        this.buttonDisabled = true;
        let formData = new FormData();
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        if (this.selectedThreat && this.selectedThreat.id) {
          formData.append("threat_id", this.selectedThreat.id);
        }

        formData.append("genericControl", this.$route.params.slug);

        piincHttp.post('/generic-controls/' + this.$route.params.slug + '/threats', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function(response) {
          self.$toastr('success', self.$t('generic_controls.threats.create.success'))
          self.hideCreatePolicyModal();
          self.buttonDisabled = false;
          self.loadThreats();
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
            if (error.data.errors.threat_id) {
              self.error += ' ' + error.data.errors.threat_id[0]
            }
          }
        })
      },
      loadThreatItems(query) {
        let self = this;
        piincHttp.get('threats', { params:
          {
            client: this.getClient.slug,
            per_page: 100,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.threatItems = response.data.items
          if (self.threatItems && !self.threatItems.length && !query) {
            self.threatItems.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadLinksItemsControl() {
        let self = this;
        piincHttp.get('threats/' + this.linksItem.threat.slug + '/risk-scenarios', { params:
          {
            client: this.getClient.slug,
            page: this.page
          }
        }).then(function(response) {
          if (response.data.items && (response.data.items.length < 10 || (response.data.items.length == 10 && response.data.total_count == 10 * self.page))) {
            self.showMore = false;
          }

          self.page += 1;
          self.loading = false;

          self.linksItems.push(...response.data.items)

        }, function() {});
      }
    },
    created () {
      if (this.getClient && this.getClient.slug) {

      }
      // Table items
      this.loadThreats();
      this.loadGuides('generic_controls_update_threat');
    }
  }
</script>
