<template>
  <div>
    <!-- Measures table -->
    <div class="card pagination-margin"
         qid="measure-section"
         id="update_measures"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <div class="float-left">
              <h5 class="mb-2" qid="measure-table-title">
                {{ $t('maintenance.measures.title') }}
              </h5>
              <span class="cursor-pointer task-label-open mr-2"
                    v-if="getTask('generic_controls_update_measures')"
                    @click="openToolbar(getTask('generic_controls_update_measures'))"
              >
                <font-awesome-icon icon="info-circle" class="text-primary"/>
                {{ $t('tasks.open_label') }}
              </span>
              <span class="task-label-active">
                <font-awesome-icon icon="info-circle" class="text-success"/>
                {{ $t('tasks.active_label') }}
              </span>
              <span v-if="getGuide('generic_controls_update_measures')">
                <GuideButton :guide="getGuide('generic_controls_update_measures')"></GuideButton>
              </span>
            </div>

          </div>
          <div class="col-sm-6 text-right">
            <button class="btn btn-success float-right"
                    @click="showCreateMeasureModal()"
                    qid="retention-rule-create-button"
                    v-if="isGranted([permissions.GENERIC_CONTROL_MEASURE_CREATE, permissions.GENERIC_CONTROL_MEASURE_CREATE_OWN], genericControl) && (!getClient.parent || (getClient.parent && genericControl.partition))"
            >
              <font-awesome-icon icon="plus"/> {{ $t('activities.measures.add_new') }}
            </button>
            <MeasureCreateForm @reloadContent="updateMeasures" v-if="isGranted(permissions.MAINTENANCE_VIEW) && checkIfAdmin()"></MeasureCreateForm>
          </div>
        </div>

        <table class="table table-responsive-md table-list-secondary">
          <thead>
          <tr>
            <th scope="col"
                class="cursor-pointer"
                @click="selectSort('name')"
                qid="sort-s-name"
            >
              {{ $t('activities.measures.table.name') }}
              <span class="ml-2">
                <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'name'"/>
                <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'name'"/>
                <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'name'"/>
              </span>
            </th>
            <th scope="col">
              {{ $t('activities.measures.table.category') }}
            </th>
            <th scope="col"
                class="cursor-pointer"
                @click="selectSort('updated_at')"
                qid="sort-s-name"
            >
              {{ $t('system.last_updated') }}
              <span class="ml-2">
                <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'updated_at'"/>
                <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'updated_at'"/>
                <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'updated_at'"/>
              </span>
            </th>
            <th width="180"></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="object in measureTableItems">
              <td>
                <button v-if="object.measure && object.measure.name"
                        @click="openViewMeasureModal(object)"
                        class="btn btn-link btn-sm text-left"
                >
                  {{ object.measure.name }}
                </button>
              </td>
              <td>
                <span v-if="object.measure && object.measure.measure_category && object.measure.measure_category.id" class="text-normal">
                  <font-awesome-icon icon="shapes"/>
                  {{ object.measure.measure_category.name }}
                </span>
              </td>
              <td>
                <span class="text-normal">
                  {{ object.updated_at | formatDate }}
                </span>
              </td>
              <td class="text-right">
                <button v-if="object.measure && object.measure.name"
                        @click="openViewMeasureModal(object)"
                        class="btn btn-view"
                >
                  <font-awesome-icon icon="eye"/>
                </button>
                <button class="btn btn-delete"
                      qid="delete-measure-modal"
                      @click="showMeasureConfirmDeleteModal(object)"
                      v-if="isGranted([permissions.GENERIC_CONTROL_MEASURE_DELETE, permissions.GENERIC_CONTROL_MEASURE_DELETE_OWN], genericControl) && (!getClient.parent || (getClient.parent && genericControl.partition))"
                >
                  <font-awesome-icon icon="trash-alt" />
                </button>
              </td>
            </tr>
            <tr v-if="!measureLoading && !measureTableItems.length" qid="no-results-row-rr" class="text-center">
              <td colspan="10">
                <h5 class="mt-3">
                  {{ $t('system.no_results') }}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card-body">
        <div class="pagination row">
          <div class="col-md-6">
            <div class="row">
              <div class="float-left">
                <span class="text-muted pagination-counter">
                  {{ $t('pagination.entries_per_page') }}:
                </span>
              </div>
              <div class="float-left">
                <multiselect
                  v-model="perPage"
                  :options="perPageOptions"
                  track-by="value"
                  label="value"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  qid="create-org-form-organization-level"
                  @select="perPageChanged"
                  :placeholder="selectedPerPage"
                  @remove="perPageRemoved"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="selectedPerPage"
                aria-controls="my-table"
                @change="pageChanged"
                limit="1"
            >
              <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
              <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
              <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
              <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
              <div slot="ellipsis-text">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
              <span class="text-muted" slot="page" slot-scope="{ page, active }">
                <span v-if="active">{{getPerPage}}</span>
              </span>
            </b-pagination>
          </div>
        </div>
      </div>

    </div>


    <!-- Delete measure modal template -->
    <b-modal ref="delete-measure" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-measure-title">
          {{ $t('activities.measures.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        {{ $t('activities.measures.delete.description') }}
        <span v-if="itemToDelete.measure">{{ itemToDelete.measure.name }}</span>
      </h5>
      <h5 class="text-center">
        {{ $t('activities.measures.delete.are_you_sure') }}
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hideMeasureConfirmDeleteModal"
                  qid="delete-measure-cancel-button"
        >
          {{ $t('activities.measures.delete.cancel') }}
        </b-button>
        <b-button @click="confirmDeleteMeasure(itemToDelete)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-measure-submit-button"
        >
          {{ $t('activities.measures.delete.submit') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Add new measure -->
    <b-modal ref="add-measure" hide-footer @hide="hideCreateMeasureModal">
      <div class="d-block text-center">
        <h4 qid="add-ac-title">
          {{ $t('activities.measures.create.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-ac-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="formSubmit" qid="create-ac-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-ac-pg-label">
              {{ $t('activities.measures.create.measure_label') }}
            </label>
            <multiselect
                v-model="selectedMeasure"
                :options="measureItems"
                :placeholder="$t('activities.measures.create.measure_placeholder')"
                label="name"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @search-change="loadMeasures"
                @select="onMeasureSelected"
                track-by="slug"
                qid="create-rr-form-rr"
            ></multiselect>
          </b-form-group>
          <div v-if="selectedMeasure && selectedMeasure.description"
               class="text-center mb-3 mt-4"
          >
            <span class="cursor-pointer">
              <span class="mr-2"
                    @click="showRRDescription = !showRRDescription"
                    qid="expand-rr-description"
              >
                {{$t('activities.measures.create.measure_description_expand')}}
              </span>
              <font-awesome-icon icon="chevron-down" v-if="!showRRDescription"/>
              <font-awesome-icon icon="chevron-up" v-if="showRRDescription"/>
            </span>
          </div>
          <div v-if="selectedMeasure && selectedMeasure.description && showRRDescription" class="mb-4 mt-2">
            <span class="text-formatted">
              {{ selectedMeasure.description }}
            </span>
          </div>
          <br>
          <div v-if="selectedMeasure && selectedMeasure.measure_category && selectedMeasure.measure_category.id">
            <label class="mt-3">
              {{ $t('activities.measures.table.category') }}
            </label>
            <br>
            <span class="mr-1">
              {{ selectedMeasure.measure_category.name }}
            </span>
          </div>

        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideCreateMeasureModal"
                    qid="create-rr-cancel-button"
          >
            {{ $t('activities.measures.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-rr-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('activities.measures.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>


    <!-- Edit measure  -->
    <b-modal ref="edit-measure" hide-footer @hide="hideEditMeasureModal" size="lg">
      <div class="d-block text-center">
        <h4 qid="add-ac-title">
          {{ $t('activities.measures.edit.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-ac-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="formEditSubmit" qid="create-ac-form">
        <div class="row">
          <div class="col-sm-12">
            <div class="card processing_type">
              <div class="row no-gutters">
                <div class="col-sm-4 card-menu">
                  <div class="card-body text-dark">
                    <p class="mt-2">{{ $t('maintenance.measures.create.name_label') }}</p>
                    <br>
                    <p>{{ $t('maintenance.measures.create.category_label') }}</p>
                    <br>
                    <p>{{ $t('maintenance.measures.create.description_label') }}</p>
                    <br>
                  </div>
                </div>
                <div class="col-sm-8">
                  <div class="card-body">

                    <div v-if="selectedTableItem && selectedTableItem.measure">
                      <p class="mt-2 text-normal">
                        <span>
                          {{ selectedTableItem.measure.name }}
                        </span>
                      </p>
                      <br>
                      <p class="text-normal">
                        <span v-if="selectedTableItem.measure.measure_category">
                          {{ selectedTableItem.measure.measure_category.name }}
                        </span>
                      </p>
                      <br>
                      <p class="text-normal">
                        <span>
                          {{ selectedTableItem.measure.description }}
                        </span>
                      </p>
                      <br>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideEditMeasureModal"
                    qid="create-rr-cancel-button"
          >
            {{ $t('activities.measures.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-rr-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('system.save') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>


    <ContentMeasureViewModal
        :object="selectedItem"
        :showModal="viewMeasureModal"
        @closeViewModal="handleCloseViewMeasureModal"
        v-if="selectedItem"
    ></ContentMeasureViewModal>

  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import roleCheck from "@/util/ACL/roleCheck"
  import ValidationList from '@/views/Validations/ValidationList.vue';
  import ValidationForm from '@/views/Validations/ValidationForm.vue';
  import ContentMeasureViewModal from '@/views/Measures/ContentMeasureViewModal.vue';
  import MeasureCreateForm from '@/views/Measures/MeasureCreateForm.vue'
  import Datepicker from 'vuejs-datepicker';
  import GuideButton from '@/components/guide/GuideButton.vue';


  export default {
    name: 'MeasureTable',
    data: function() {
      return {
        form: {
          response: ''
        },
        error: '',
        loadingItems: false,
        selectedMeasure: [],
        selectedTableItem: [],
        viewRRModal: false,
        editRRModal: false,
        selectedMeasureSort: '',
        selectedMeasureDirection: '',
        acValidationShow: false,
        measureTableItems: [],
        measureLoading: true,
        measureItems: [],
        updateValidationList: false,
        itemToDelete: {},
        itemObject: {},
        reCertificationOptions: [],
        provisioningOptions: [],
        selectedRC: [],
        selectedP: [],
        validationOptions: [],
        validation: undefined,
        disabledValidationOptions: [],
        showRRDescription: false,
        buttonDisabled: false,
        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],
        perPage: '',
        selectedPerPage: "10",
        currentPage: 1,
        totalRows: 0,
        selectedSort: '',
        selectedDirection: '',
        filterOpened: false,
        keywordValue: '',
        selectedItem: {},
        viewMeasureModal: false,
        myGuides: [],
        selectedAnswer: [],
        selectedDate: '',
        selectedNumber: '',
        allAnswers: [],
        genericControl: {}
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      },
      tasks: {
        type: Array
      }
    },
    components: {
      Avatar,
      ValidationList,
      ValidationForm,
      ContentMeasureViewModal,
      Datepicker,
      MeasureCreateForm,
      GuideButton
    },
    computed: {
      rows() {
        // Use computed value for rows because of dynamic update
        return this.totalRows
      },
      getClient() {
        return this.$store.state.client
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getPerPage() {
        let page = this.currentPage;
        let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
        let selectedOf = this.totalRows;

        if(selectedTo > selectedOf){
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadMeasures();
      },
      object: function (value) {
        this.genericControl = value
      }
    },
    methods: {
      debounceInput: _.debounce(function() {
        this.loadTableMeasures()
      }, 400),
      getNumberValue(number) {
        let transformed = ''
        if (number) {
          transformed = number.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")
        }
        return transformed
      },
      checkIfAdmin() {
        if (roleCheck('Client admin') || roleCheck('Partition admin')) {
          return true
        } else {
          return false
        }
      },
      openViewMeasureModal(object) {
        this.selectedItem = object
        this.viewMeasureModal = true
      },
      handleCloseViewMeasureModal () {
        this.selectedItem = []
        this.viewMeasureModal = false;
      },
      onMeasureSelected(object) {
        if (object.type == 'Select') {
          this.allAnswers = JSON.parse(object.type_options)
        } else {
          this.allAnswers = ['Yes', 'No']
        }
      },
      selectSort(sort) {
        // If old and new sort are same, switch just direction
        if (sort === this.selectedSort) {
          this.selectedDirection = this.selectedDirection === 'desc' ? 'asc' : 'desc';
        } else {
          // If different sort, set sort and set direction to default
          this.selectedDirection = 'desc'
          this.selectedSort = sort;
        }
        this.loadTableMeasures();
      },
      getTask(code) {
        let existingTask = _.find(this.tasks, function(object) {
          return object.code == code
        });
        return existingTask
      },
      openToolbar(object) {
        this.$store.commit('storeTaskToolbarState', true);
        this.$store.commit('storeActiveTask', object);
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Generic control' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Generic control',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
          }
        }, function() {});
      },
      showCreateMeasureModal() {
        this.selectedAnswer = []
        this.selectedMeasure = []
        this.selectedDate = ''
        this.selectedNumber = ''
        this.$refs['add-measure'].show()
      },
      hideCreateMeasureModal() {
        this.error = ''
        this.selectedTableItem = []
        this.loadContent()
        this.$refs['add-measure'].hide()
      },
      showEditMeasureModal(object) {
        let self = this;
        this.selectedTableItem = object

        if (object.measure.value == 'dropdown') {
          if (object.measure.type == 'Select') {
            this.allAnswers = JSON.parse(object.measure.type_options)
          } else {
            this.allAnswers = ['Yes', 'No']
          }

          self.selectedAnswer = []
          if (self.selectedTableItem.response) {
            self.selectedAnswer = _.find(self.allAnswers, function(item) {
              if(item === self.selectedTableItem.response){
                return self.selectedTableItem.response;
              }
            });
          }
        }else if (object.measure.value == 'date') {
          self.selectedDate = self.selectedTableItem.response;
        }else if (object.measure.value == 'number') {
          self.selectedNumber = self.selectedTableItem.response;
        }

        this.$refs['edit-measure'].show()
      },
      hideEditMeasureModal() {
        this.error = ''
        this.selectedMeasure = []
        this.selectedAnswer = []
        this.selectedDate = ''
        this.selectedNumber = ''
        this.loadContent()
        this.$refs['edit-measure'].hide()
      },
      showMeasureConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.$refs['delete-measure'].show()
      },
      hideMeasureConfirmDeleteModal() {
        this.itemToDelete = {}
        this.loadContent()
        this.$refs['delete-measure'].hide()
      },
      confirmDeleteMeasure(object) {
        let self = this;
        piincHttp.delete('generic-controls/' + this.$route.params.slug + '/measures/' + object.id).then(function() {
          self.hideMeasureConfirmDeleteModal();
          self.loadTableMeasures();
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('activities.measures.delete.success'))
        }, function() {});
      },
      formSubmit(evt) {
        this.buttonDisabled = true;
        let formData = new FormData();

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        if (this.selectedMeasure && this.selectedMeasure.id) {
          formData.append("measure_id", this.selectedMeasure.id);
        }

        if (this.selectedAnswer && this.selectedAnswer.length > 0) {
          formData.append("response", this.selectedAnswer.toString());
        }else if (this.selectedDate) {
          formData.append("response", this.selectedDate);
        }else if (this.selectedNumber) {
          formData.append("response", this.selectedNumber);
        }

        piincHttp.post('/generic-controls/' + this.$route.params.slug + '/measures', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function(response) {
          self.$toastr('success', self.$t('activities.measures.create.success'))
          self.hideCreateMeasureModal();
          self.loadTableMeasures();
          self.$emit('updated');
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
            if (error.data.errors.measure_id) {
              self.error += ' ' + error.data.errors.measure_id[0]
            }
          }
        })
      },
      formEditSubmit(evt) {
        this.buttonDisabled = true;

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();


        if (this.selectedAnswer && this.selectedAnswer.length > 0) {
          this.form.response = this.selectedAnswer.toString();
        }else if (this.selectedDate) {
          this.form.response = this.selectedDate;
        }else if (this.selectedNumber) {
          this.form.response = this.selectedNumber;
        }

        piincHttp.put('/generic-controls/' + this.$route.params.slug + '/measures/' + this.selectedTableItem.id, this.form).then(function(response) {
          self.hideEditMeasureModal();
          self.loadTableMeasures(self.currentPage);
          self.buttonDisabled = false;
          self.$toastr('success', self.$t('maintenance.measures.update.success'))
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      loadContent() {
        let self = this;
        piincHttp.get('generic-controls/' + this.$route.params.slug, { params:
          {
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.genericControl = response.data;
        }, function() {});
      },
      loadTableMeasures(page, perPage) {

        if (this.getStorePerPage) {
          this.selectedPerPage = this.getStorePerPage;
        }

        // If page is not defined, set page to default 1
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.selectedPerPage;
        let self = this;

        piincHttp.get('generic-controls/' + this.$route.params.slug + '/measures', { params:
            {
              page: pageNumber,
              per_page: perPageNumber,
              sort: this.selectedSort !== '' ? this.selectedSort : undefined,
              order: this.selectedDirection !== '' ? this.selectedDirection : undefined,
              keyword: this.keywordValue !== '' ? this.keywordValue : undefined,
              client: this.getClient.slug
            }
        }).then(function(response) {

          self.measureLoading = false;
          self.measureTableItems = response.data.items;
          // Set number of total items
          self.totalRows = response.data.total_count;
        }, function() {});
      },
      pageChanged(page) {
        // Load Third parties when page changed listener is triggered
        this.loadTableMeasures(page)
      },
      perPageChanged(perPage) {
        let self = this;

        self.selectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.selectedPerPage);
        // Load Third parties when page changed listener is triggered
        this.loadTableMeasures(1, perPage.value)
      },
      perPageRemoved() {
        let self = this;
        // Load Third parties when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value
        this.loadTableMeasures(1, self.selectedPerPage)

      },
      updateMeasures(object) {
        this.loadMeasures()
      },
      loadMeasures(query) {
        let self = this;
        piincHttp.get('measures', { params:
          {
            client: this.getClient.slug,
            per_page: 100,
            scope: this.genericControl.type != 'Security measure' ? this.genericControl.scopes.toString() : undefined,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.measureItems = response.data.items
          if (self.measureItems && !self.measureItems.length && !query) {
            self.measureItems.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
    },
    created () {
      this.genericControl = this.object
      if (this.getClient && this.getClient.slug) {
        this.loadMeasures();
      }
      // Table items
      this.loadTableMeasures();
      this.loadGuides('generic_controls_update_measures')
    }
  }
</script>
