<template>
  <div>
    <!-- Project templates table -->
    <div class="card pagination-margin"
         qid="projects-section"
         id="update_projects"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <h5 qid="projects-table-title" class="mb-2">
              {{ $t('generic_controls.projects.title') }}
            </h5>
            <span class="cursor-pointer task-label-open mr-2"
                  v-if="getTask('update_projects')"
                  @click="openToolbar(getTask('update_projects'))"
            >
              <font-awesome-icon icon="info-circle" class="text-primary"/>
              {{ $t('tasks.open_label') }}
            </span>
            <span v-if="getGuide('generic_controls_update_projects')">
              <GuideButton :guide="getGuide('generic_controls_update_projects')"></GuideButton>
            </span>
          </div>
        </div>

        <table class="table table-responsive-md table-list-secondary">
          <thead>
            <tr>
              <th scope="col">
                {{ $t('generic_controls.projects.table.name') }}
              </th>
              <th scope="col">
                {{ $t('projects.table.owners') }}
              </th>
              <th scope="col">
                {{ $t('generic_controls.projects.table.last_performed') }}
              </th>
              <th scope="col">
                {{ $t('generic_controls.projects.table.last_status') }}
              </th>
              <th scope="col">
                {{ $t('system.last_updated') }}
              </th>
              <th width="100">

              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in projectTemplateTableItems">
              <td scope="row">
                <button v-if="item.project"
                   @click="openViewProjectTemplateModal(item)"
                   qid="io-name-link"
                   class="btn btn-link text-left"
                >
                  {{ item.project.title }}
                </button>
              </td>
              <td>
                <span v-if="item.project.users && item.project.users.length">
                  <span class="mr-1"
                        v-for="user in getReducedUsers(item.project.users)"
                  >
                    <span qid="profile-photo">
                      <Avatar :object="user" size="xs"></Avatar>
                    </span>
                  </span>
                  <button v-if="item.project.users && item.project.users.length > 3"
                          class="badge badge-info mr-1 rounded-circle"
                          @click="openUsersModal(item.project)"
                  >
                    + {{ item.project.users.length - 3 }}
                  </button>
                </span>
              </td>
              <td>
                <span class="text-normal">
                  <span v-if="item.project.last_performed && item.project.last_performed.start_date">
                    {{ item.project.last_performed.start_date | formatDate }}
                  </span>
                  <span v-else>
                    {{ $t('system.never')}}
                  </span>
                  <br/>
                  <span v-if="item.project && item.project.repeat_after && item.project.scheduled" class="text-muted">
                    {{ $t('system.every') }}
                    {{item.project.repeat_after}}
                    {{ $t('system.interval_' + $options.filters.slugify(item.project.repeat_after_interval)) }}
                  </span>
                  <span v-if="!item.project.scheduled">
                    {{ $t('system.not_scheduled')}}
                  </span>
                </span>
              </td>
              <td>
                <span v-if="item.project.last_status" class="text-normal">
                  <span v-if="item.project.last_status == 'fail'">
                    <font-awesome-icon
                      icon="exclamation-triangle"
                      class="risk-12"
                    />
                    {{ $t('system.failed')}}
                  </span>
                  <span v-else-if="item.project.last_status == 'pass'">
                    <font-awesome-icon
                      icon="exclamation-triangle"
                      class="risk-1"
                    />
                    {{ $t('system.passed')}}
                  </span>
                  <span v-else-if="item.project.last_status == 'in_progress'">
                    <font-awesome-icon
                      icon="exclamation-triangle"
                      class="risk-3"
                    />
                    {{ $t('system.in_progress')}}
                  </span>
                  <span v-else>
                    <font-awesome-icon
                      icon="exclamation-triangle"
                      class="risk-0"
                    />
                    {{ $t('system.not_performed')}}
                  </span>
                </span>
              </td>
              <td>
                <span class="text-normal">
                  {{ item.project.updated_at | formatDate }}
                </span>
              </td>
              <td align="right">
                <button v-if="item.project"
                        @click="openViewProjectTemplateModal(item)"
                        qid="io-name-link"
                        class="btn btn-view"
                >
                  <font-awesome-icon icon="eye"/>
                </button>
              </td>
            </tr>
            <tr v-if="!projectTemplatesLoading && !projectTemplateTableItems.length" qid="no-results-row" class="text-center">
              <td colspan="10">
                <h5 class="mt-3">
                  {{ $t('system.no_results') }}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card-body">
        <div class="pagination row">
          <div class="col-md-6">
            <div class="row">
              <div class="float-left">
                <span class="text-muted pagination-counter">
                  {{ $t('pagination.entries_per_page') }}:
                </span>
              </div>
              <div class="float-left">
                <multiselect
                    v-model="perPage"
                    :options="perPageOptions"
                    track-by="value"
                    label="value"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-org-form-organization-level"
                    @select="perPageChanged"
                    :placeholder="selectedPerPage"
                    @remove="perPageRemoved"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="selectedPerPage"
                aria-controls="my-table"
                @change="pageChanged"
                limit="1"
            >
              <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
              <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
              <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
              <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
              <div slot="ellipsis-text">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
              <span class="text-muted" slot="page" slot-scope="{ page, active }">
                <span v-if="active">{{getPerPage}}</span>
              </span>
            </b-pagination>
          </div>
        </div>
      </div>

    </div>

    <ProjectTemplateViewModal
        :object="selectedProjectTemplate"
        :showModal="viewProjectTemplateModal"
        @closeViewModal="handleCloseViewProjectTemplateModal"
    ></ProjectTemplateViewModal>

  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import PolicyViewModal from '@/views/Processes/PolicyViewModal.vue';
  import ProjectTemplateViewModal from '@/views/GenericControls/ProjectTemplateViewModal.vue';
  import GuideButton from '@/components/guide/GuideButton.vue';

  export default {
    name: 'ProjectTemplatesTable',
    data: function() {
      return {
        error: '',
        loadingItems: false,
        itemToDelete: {},
        buttonDisabled: false,
        projectTemplateTableItems: [],
        projectTemplatesLoading: true,
        projectTemplateLoading: true,
        showPolicyDescription: false,
        selectedPolicy: [],
        viewPolicyModal: false,
        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],
        perPage: '',
        selectedPerPage: "10",
        currentPage: 1,
        totalRows: 0,
        selectedProjectTemplate: {},
        viewProjectTemplateModal: false,
        myGuides: []
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      },
      tasks: {
        type: Array
      }
    },
    components: {
      Avatar,
      PolicyViewModal,
      ProjectTemplateViewModal,
      GuideButton
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getPerPage() {
        let page = this.currentPage;
        let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
        let selectedOf = this.totalRows;

        if (selectedTo > selectedOf) {
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
      },
      rows() {
        // Use computed value for rows because of dynamic update
        return this.totalRows
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {

      }
    },
    methods: {
      pageChanged(page) {
        this.loadProjectTemplates(page)
      },
      perPageChanged(perPage) {
        let self = this;
        self.selectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.selectedPerPage);
        // Load content when page changed listener is triggered
        this.loadProjectTemplates(1, perPage.value)
      },
      perPageRemoved() {
        let self = this;
        // Load content when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value
        this.loadProjectTemplates(1, self.selectedPerPage)
      },
      openViewProjectTemplateModal(object) {
        this.selectedProjectTemplate = object
        this.viewProjectTemplateModal = true
      },
      handleCloseViewProjectTemplateModal () {
        this.viewProjectTemplateModal = false;
      },
      getTask(code) {
        let existingTask = _.find(this.tasks, function(object) {
          return object.code == code
        });
        return existingTask
      },
      openToolbar(object) {
        this.$store.commit('storeTaskToolbarState', true);
        this.$store.commit('storeActiveTask', object);
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Generic control' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Generic control',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
          }
        }, function() {});
      },
      openUsersModal(object) {
        this.selectedItem = object
        this.showUsersModal = true
      },
      closeUsersModal() {
        this.selectedItem = {}
        this.showUsersModal = false
      },
      getReducedUsers(users) {
        let reducedUsers = []
        if (users && users.length > 3) {
          reducedUsers = _.cloneDeep(users);
          reducedUsers = reducedUsers.slice(0, 3);
        } else {
          reducedUsers = users;
        }
        return reducedUsers;
      },
      closeViewModal () {
        this.viewDDModal = false;
        this.selectedDD = []
      },
      openViewModal(object) {
        this.selectedDD = object
        this.viewDDModal = true
      },
      loadProjectTemplates(page, perPage) {

        if (this.getStorePerPage) {
          this.selectedPerPage = this.getStorePerPage;
        }

        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.selectedPerPage;
        let self = this;

        piincHttp.get('generic-controls/' + this.$route.params.slug + '/projects', { params:
          {
            page: pageNumber,
            per_page: perPageNumber,
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.projectTemplateTableItems = response.data.items;
          self.projectTemplatesLoading = false;
          self.totalRows = response.data.total_count;
        }, function() {});
      }
    },
    created () {
      if (this.getClient && this.getClient.slug) {

      }
      // Table items
      this.loadProjectTemplates();
      this.loadGuides('generic_controls_update_projects');
    }
  }
</script>
