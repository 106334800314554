<template>
  <div>
    <!-- Policies and procedures table -->
    <div v-if="isGranted([permissions.GENERIC_CONTROL_POLICY_VIEW, permissions.GENERIC_CONTROL_POLICY_VIEW_OWN], object)"
         class="card"
         qid="policies-section"
         id="update_policies_and_procedures"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <h5 qid="policy-table-title" class="mb-2">
              {{ $t('processes.policies.title') }}
            </h5>
            <span class="cursor-pointer task-label-open mr-2"
                  v-if="getTask('update_policies_and_procedures')"
                  @click="openToolbar(getTask('update_policies_and_procedures'))"
            >
              <font-awesome-icon icon="info-circle" class="text-primary"/>
              {{ $t('tasks.open_label') }}
            </span>
            <span v-if="getGuide('generic_controls_update_policies')">
              <GuideButton :guide="getGuide('generic_controls_update_policies')"></GuideButton>
            </span>
          </div>
          <div class="col-sm-6 text-right">
            <button class="btn btn-success"
                    qid="policy-create-button"
                    @click="showCreatePolicyModal"
                    v-if="isGranted([permissions.GENERIC_CONTROL_POLICY_CREATE, permissions.GENERIC_CONTROL_POLICY_CREATE_OWN], object) && (!getClient.parent || (getClient.parent && object.partition))"
            >
              <font-awesome-icon icon="plus"/> {{ $t('processes.policies.create_button') }}
            </button>
          </div>
        </div>

        <table class="table table-responsive-md table-list-secondary">
          <thead>
            <tr>
              <th scope="col">
                {{ $t('processes.policies.table.name') }}
              </th>
              <th scope="col">
                {{ $t('maintenance.policies.table.category') }}
              </th>
              <th scope="col">
                {{ $t('system.last_updated') }}
              </th>
              <th width="130"></th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="item in policyTableItems">
            <td scope="row">
              <button v-if="item.policy"
                      @click="openViewPolicyModal(item.policy)"
                      qid="policy-name-link"
                      class="btn btn-link cursor-pointer text-left"
              >
                {{ item.policy.name }}
              </button>
            </td>
            <td>
              <span qid="policy-category"
                    :title="$t('maintenance.policies.category.' + $options.filters.slugify(item.policy.category))"
                    v-if="item.policy.category"
                    class="text-normal"
              >
                <font-awesome-icon icon="shapes"/>
                {{ $t('maintenance.policies.category.' + $options.filters.slugify(item.policy.category)) }}
              </span>
            </td>
            <td>
              <span class="text-normal">
                {{ item.policy.updated_at | formatDate }}
              </span>
            </td>
            <td align="right">
              <button v-if="item.policy"
                      @click="openViewPolicyModal(item.policy)"
                      qid="policy-name-link"
                      class="btn btn-view"
              >
                <font-awesome-icon icon="eye"/>
              </button>
              <button v-if="isGranted([permissions.GENERIC_CONTROL_POLICY_DELETE, permissions.GENERIC_CONTROL_POLICY_DELETE_OWN], object) && (!getClient.parent || (getClient.parent && object.partition))"
                      class="btn btn-delete"
                      qid="delete-policy-modal"
                      @click="showPolicyConfirmDeleteModal(item)"
              >
                <font-awesome-icon icon="trash-alt"/>
              </button>
            </td>
          </tr>
          <tr v-if="!policyLoading && !policyTableItems.length" qid="no-results-row" class="text-center">
            <td colspan="10">
              <h5 class="mt-3">
                {{ $t('system.no_results') }}
              </h5>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


    <!-- Delete policy modal template -->
    <b-modal ref="delete-policy" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-lb-title">
          {{ $t('processes.policies.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        {{ $t('processes.policies.delete.description') }}
        <span v-if="itemToDelete.policy">{{ itemToDelete.policy.name }}</span>
      </h5>
      <h5 class="text-center">
        {{ $t('processes.policies.delete.are_you_sure') }}
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hidePolicyConfirmDeleteModal"
                  qid="delete-policy-cancel-button"
        >
          {{ $t('processes.policies.delete.cancel') }}
        </b-button>
        <b-button @click="confirmDeletePolicy(itemToDelete)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-policy-submit-button"
                  v-if="isGranted([permissions.GENERIC_CONTROL_POLICY_DELETE, permissions.GENERIC_CONTROL_POLICY_DELETE_OWN], object)"
        >
          {{ $t('processes.policies.delete.submit') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Add new policy -->
    <b-modal ref="add-policy" hide-footer @hide="hideCreatePolicyModal">
      <div class="d-block text-center">
        <h4 qid="add-io-title">
          {{ $t('processes.policies.create.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-io-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="policyFormSubmit" qid="create-policy-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-policy-pg-label">
              {{ $t('processes.policies.create.policy_label') }}
            </label>
            <multiselect
                  v-model="selectedPolicy"
                  :options="policyItems"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  :placeholder="$t('processes.policies.create.policy_placeholder')"
                  label="name"
                  @search-change="loadPolicy"
                  track-by="slug"
                  qid="create-lb-form-pg"
            ></multiselect>
          </b-form-group>
          <div v-if="selectedPolicy && selectedPolicy.description"
               class="text-center mb-3 mt-4"
          >
            <span class="cursor-pointer">
              <span class="mr-2"
                    @click="showPolicyDescription = !showPolicyDescription"
                    qid="expand-policy-description"
              >
                {{$t('processes.policies.create.policy_description_expand')}}
              </span>
              <font-awesome-icon icon="chevron-down" v-if="!showPolicyDescription"/>
              <font-awesome-icon icon="chevron-up" v-if="showPolicyDescription"/>
            </span>
          </div>
          <div v-if="selectedPolicy && selectedPolicy.description && showPolicyDescription" class="mb-4 mt-2">
            <span class="text-formatted">
              {{ selectedPolicy.description }}
            </span>
          </div>
          <div v-if="selectedPolicy && selectedPolicy.implementation_status" class="mb-3">
            <label class="mt-3">
                {{ $t('maintenance.policies.create.implementation_status_label') }}
            </label>
            <br>
            <span v-if="selectedPolicy">
              {{ selectedPolicy.implementation_status }}
            </span>
          </div>
          <div v-if="selectedPolicy && selectedPolicy.category" class="mb-3">
            <label>
              {{ $t('maintenance.policies.create.category_label') }}
            </label>
            <br>
            <span v-if="selectedPolicy">
              {{ $t('maintenance.policies.category.' + $options.filters.slugify(selectedPolicy.category)) }}
            </span>
          </div>
          <b-form-group v-if="selectedPolicy && selectedPolicy.media && selectedPolicy.media.length">
            <label qid="create-tp-attachments-label">
              {{ $t('maintenance.third_parties.create.attachments') }}
            </label>
            <br>
            <p v-for="file in selectedPolicy.media" :key="file.file_name">
              <span class="mr-3 float-right text-muted cursor-pointer"
                    @click="downloadMedia(file)"
                    qid="download-media"
              >
                <font-awesome-icon icon="cloud-download-alt" />
              </span>
              <a @click="downloadMedia(file)" qid="create-tp-media-item">
                {{ file.file_name }}
              </a>
            </p>

          </b-form-group>
          <b-form-group v-if="selectedPolicy && selectedPolicy.links && selectedPolicy.links.length">
            <label qid="create-tp-link-label">
              {{ $t('maintenance.third_parties.create.links_label') }}
            </label>
            <p v-for="(link, index) in selectedPolicy.links">
              <a :href="link.url" target="_blank" qid="create-tp-link-item">
                {{ link.name ? link.name : link.url }}
              </a>
            </p>

          </b-form-group>

        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideCreatePolicyModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('processes.policies.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-p-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('processes.policies.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>

    <PolicyViewModal
        :object="selectedPolicy"
        :showModal="viewPolicyModal"
        @closeViewModal="handleCloseViewPModal"
    ></PolicyViewModal>

  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import PolicyViewModal from '@/views/Policies/PolicyViewModal.vue';
  import GuideButton from '@/components/guide/GuideButton.vue';

  export default {
    name: 'PolicyTable',
    data: function() {
      return {
        ddForm: {
          name: '',
          data_scanning: '',
          description: ''
        },
        error: '',
        loadingItems: false,
        itemToDelete: {},
        buttonDisabled: false,
        policyTableItems: [],
        policyLoading: true,
        policyValidationShow: false,
        showPolicyDescription: false,
        policyItems: [],
        selectedPolicy: [],
        viewPolicyModal: false,
        myGuides: []
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      },
      tasks: {
        type: Array
      }
    },
    components: {
      Avatar,
      PolicyViewModal,
      GuideButton
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadPolicy();
      }
    },
    methods: {
      getTask(code) {
        let existingTask = _.find(this.tasks, function(object) {
          return object.code == code
        });
        return existingTask
      },
      openToolbar(object) {
        this.$store.commit('storeTaskToolbarState', true);
        this.$store.commit('storeActiveTask', object);
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Generic control' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Generic control',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
          }
        }, function() {});
      },
      resetFields() {
        this.selectedDropdownOption = null
      },
      openViewPolicyModal(object) {
        this.selectedPolicy = object
        this.viewPolicyModal = true
      },
      handleCloseViewPModal () {
        this.viewPolicyModal = false;
        this.selectedPolicy = []
      },
      closeViewModal () {
        this.viewDDModal = false;
        this.selectedDD = []
      },
      openViewModal(object) {
        this.selectedDD = object
        this.viewDDModal = true
      },
      showCreatePolicyModal() {
        this.$refs['add-policy'].show()
      },
      hideCreatePolicyModal() {
        this.error = ''
        this.selectedPolicy = [];
        this.showPolicyDescription = false;
        this.$refs['add-policy'].hide()
      },
      showPolicyConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.$refs['delete-policy'].show()
      },
      hidePolicyConfirmDeleteModal() {
        this.itemToDelete = {}
        this.$refs['delete-policy'].hide()
      },
      loadPolicies() {
        let self = this;
        piincHttp.get('generic-controls/' + this.$route.params.slug + '/policies', { params:
          {
            client: this.getClient.slug,
          }
        }).then(function(response) {
          self.policyTableItems = response.data;
          self.policyLoading = false;
        }, function() {});
      },
      confirmDeletePolicy(object) {
        let self = this;
        piincHttp.delete('generic-controls/' + this.object.slug + '/policies/' + object.id).then(function() {
          self.hidePolicyConfirmDeleteModal();
          self.loadPolicies();
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('processes.policies.delete.success'))
        }, function() {});
      },
      policyFormSubmit(evt) {
        this.buttonDisabled = true;
        let formData = new FormData();
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        if (this.selectedPolicy && this.selectedPolicy.id) {
          formData.append("policy_id", this.selectedPolicy.id);
        }

        formData.append("generic_control", this.$route.params.slug);

        piincHttp.post('/generic-controls/' + this.$route.params.slug + '/policies', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function(response) {
          self.$toastr('success', self.$t('processes.policies.create.success'))
          self.hideCreatePolicyModal();
          self.buttonDisabled = false;
          self.loadPolicies();
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
            if (error.data.errors.policy_id) {
              self.error += ' ' + error.data.errors.policy_id[0]
            }
          }
        })
      },
      loadPolicy(query) {
        let self = this;
        piincHttp.get('policies', { params:
          {
            client: this.getClient.slug,
            per_page: 100,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.policyItems = response.data.items
          if (self.policyItems && !self.policyItems.length && !query) {
            self.policyItems.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
    },
    created () {
      if (this.getClient && this.getClient.slug) {
        this.loadPolicy();
      }
      // Table items
      this.loadPolicies();
      this.loadGuides('generic_controls_update_policies');
    }
  }
</script>
