<template>
  <div>
    <!-- Framework controls table -->
    <div v-if="isGranted([permissions.GENERIC_CONTROL_FRAMEWORK_VIEW, permissions.GENERIC_CONTROL_FRAMEWORK_VIEW_OWN], object)"
         class="card"
         qid="framework-controls-section"
         id="update_framework_controls"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <h5 qid="framework-control-table-title" class="mb-2">
              {{ $t('generic_controls.framework_controls.title') }}
            </h5>
            <span class="cursor-pointer task-label-open mr-2"
                  v-if="getTask('update_framework_controls')"
                  @click="openToolbar(getTask('update_framework_controls'))"
            >
              <font-awesome-icon icon="info-circle" class="text-primary"/>
              {{ $t('tasks.open_label') }}
            </span>
            <span v-if="getGuide('generic_controls_update_framework_controls')">
              <GuideButton :guide="getGuide('generic_controls_update_framework_controls')"></GuideButton>
            </span>
          </div>
          <div class="col-sm-6 text-right">
            <button class="btn btn-success"
                    qid="frameworkControl-create-button"
                    @click="showCreatePolicyModal"
                    v-if="isGranted([permissions.GENERIC_CONTROL_FRAMEWORK_CREATE, permissions.GENERIC_CONTROL_FRAMEWORK_CREATE_OWN], object) && (!getClient.parent || (getClient.parent && object.partition))"
            >
              <font-awesome-icon icon="plus"/> {{ $t('generic_controls.framework_controls.create_button') }}
            </button>
          </div>
        </div>

        <table class="table table-responsive-md table-list-secondary">
          <thead>
            <tr>
              <th scope="col">
                {{ $t('generic_controls.framework_controls.name') }}
              </th>
              <th scope="col">
                {{ $t('generic_controls.framework_controls.framework') }}
              </th>
              <th scope="col">
                {{ $t('system.last_updated') }}
              </th>
              <th width="130"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in frameworkControlTableItems">
              <td scope="row">
                <button v-if="item.framework_control"
                        @click="openViewPolicyModal(item)"
                        qid="framework_control-name-link"
                        class="btn btn-link text-left"
                >
                  {{ item.framework_control.full_name }}
                </button>
              </td>
              <td>
                <span class="mr-1" v-if="item.framework_control && item.framework_control.active_framework" qid="item-framework">
                  <span class="badge mb-1" :title="item.framework_control.active_framework.name">
                    {{ item.framework_control.active_framework.name | truncate(35) }}
                  </span>
                </span>
              </td>
              <td>
                <span class="text-normal">
                  {{ item.framework_control.updated_at | formatDate }}
                </span>
              </td>
              <td>
                <button v-if="item.framework_control"
                        @click="openViewPolicyModal(item)"
                        qid="framework_control-name-link"
                        class="btn btn-view"
                >
                  <font-awesome-icon icon="eye"/>
                </button>

                <button v-if="isGranted([permissions.GENERIC_CONTROL_FRAMEWORK_DELETE, permissions.GENERIC_CONTROL_FRAMEWORK_DELETE_OWN], object) && (!getClient.parent || (getClient.parent && object.partition))"
                        class="btn btn-delete"
                        qid="delete-framework_control-modal"
                        @click="showPolicyConfirmDeleteModal(item)"
                >
                  <font-awesome-icon icon="trash-alt"/>
                </button>
              </td>
            </tr>
            <tr v-if="!frameworkControlLoading && !frameworkControlTableItems.length" qid="no-results-row" class="text-center">
              <td colspan="10">
                <h5 class="mt-3">
                  {{ $t('system.no_results') }}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


    <!-- Delete framework control modal template -->
    <b-modal ref="delete-framework-control" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-lb-title">
          {{ $t('generic_controls.framework_controls.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        {{ $t('generic_controls.framework_controls.delete.description') }}
        <span v-if="itemToDelete.framework_control">{{ itemToDelete.framework_control.name }}</span>
      </h5>
      <h5 class="text-center">
        {{ $t('generic_controls.framework_controls.delete.are_you_sure') }}
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hidePolicyConfirmDeleteModal"
                  qid="delete-framework-control-cancel-button"
        >
          {{ $t('generic_controls.framework_controls.delete.cancel') }}
        </b-button>
        <b-button @click="confirmDeletePolicy(itemToDelete)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-framework-control-submit-button"
                  v-if="isGranted([permissions.GENERIC_CONTROL_FRAMEWORK_DELETE, permissions.GENERIC_CONTROL_FRAMEWORK_DELETE_OWN], object)"
        >
          {{ $t('generic_controls.framework_controls.delete.submit') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Add new framework control -->
    <b-modal ref="add-framework-control" hide-footer @hide="hideCreatePolicyModal">
      <div class="d-block text-center">
        <h4 qid="add-io-title">
          {{ $t('generic_controls.framework_controls.create.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-io-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="frameworkControlFormSubmit" qid="create-framework-control-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-framewor-control-pg-label">
              {{ $t('generic_controls.framework_controls.create.framework_label') }}
            </label>
            <multiselect
                v-model="selectedFramework"
                :options="frameworkItems"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('generic_controls.framework_controls.create.framework_placeholder')"
                label="name"
                @search-change="loadFrameworkItems"
                @select="handleFrameworkChange"
                track-by="slug"
                qid="create-lb-form-pg"
            ></multiselect>
          </b-form-group>
          <b-form-group v-if="selectedFramework && selectedFramework.id">
            <label qid="create-framewor-control-pg-label">
              {{ $t('generic_controls.framework_controls.create.framework_control_label') }}
            </label>
            <multiselect
                v-model="selectedFrameworkControl"
                :options="frameworkControlItems"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('generic_controls.framework_controls.create.framework_control_placeholder')"
                label="full_name"
                @search-change="loadFrameworkControlItems"
                track-by="id"
                qid="create-lb-form-pg"
            ></multiselect>
          </b-form-group>
          <div class="text-muted mt-2 mb-3">
            {{ $t('generic_controls.framework_controls.create.framework_control_tip') }}
          </div>
        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideCreatePolicyModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('generic_controls.framework_controls.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-p-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('generic_controls.framework_controls.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>

    <FrameworkControlViewModal
        :object="selectedViewFrameworkControl.framework_control"
        :showModal="viewFrameworkControlModal"
        @closeViewModal="handleCloseViewPModal"
    ></FrameworkControlViewModal>

  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import FrameworkControlViewModal from '@/views/Frameworks/FrameworkControlViewModal.vue';
  import GuideButton from '@/components/guide/GuideButton.vue';


  export default {
    name: 'FrameworkControlsTable',
    data: function() {
      return {
        error: '',
        loadingItems: false,
        itemToDelete: {},
        buttonDisabled: false,
        frameworkControlTableItems: [],
        frameworkControlLoading: true,
        frameworkControlValidationShow: false,
        showPolicyDescription: false,
        frameworkItems: [],
        selectedFramework: [],
        frameworkControlItems: [],
        selectedFrameworkControl: [],
        selectedViewFrameworkControl: [],
        viewFrameworkControlModal: false,
        myGuides: []
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      },
      tasks: {
        type: Array
      },
    },
    components: {
      Avatar,
      FrameworkControlViewModal,
      GuideButton
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadFrameworkItems();
      }
    },
    methods: {
      getTask(code) {
        let existingTask = _.find(this.tasks, function(object) {
          return object.code == code
        });
        return existingTask
      },
      openToolbar(object) {
        this.$store.commit('storeTaskToolbarState', true);
        this.$store.commit('storeActiveTask', object);
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Generic control' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Generic control',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
          }
        }, function() {});
      },
      resetFields() {
        this.selectedDropdownOption = null
      },
      openViewPolicyModal(object) {
        this.selectedViewFrameworkControl = object
        this.viewFrameworkControlModal = true
      },
      handleCloseViewPModal () {
        this.viewFrameworkControlModal = false;
        this.selectedViewFrameworkControl = []
      },
      closeViewModal () {
        this.viewDDModal = false;
        this.selectedDD = []
      },
      openViewModal(object) {
        this.selectedDD = object
        this.viewDDModal = true
      },
      showCreatePolicyModal() {
        this.selectedFramework = [];
        this.selectedFrameworkControl = [];
        this.loadFrameworkItems();
        this.$refs['add-framework-control'].show()
      },
      hideCreatePolicyModal() {
        this.error = ''
        this.selectedFramework = [];
        this.selectedFrameworkControl = []
        this.showPolicyDescription = false;
        this.$refs['add-framework-control'].hide()
      },
      showPolicyConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.$refs['delete-framework-control'].show()
      },
      hidePolicyConfirmDeleteModal() {
        this.itemToDelete = {}
        this.$refs['delete-framework-control'].hide()
      },
      loadFrameworkControls() {
        let self = this;
        piincHttp.get('generic-controls/' + this.$route.params.slug + '/framework-controls', { params:
          {
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.frameworkControlTableItems = response.data;
          self.frameworkControlLoading = false;
        }, function() {});
      },
      confirmDeletePolicy(object) {
        let self = this;
        piincHttp.delete('generic-controls/' + this.object.slug + '/framework-controls/' + object.id).then(function() {
          self.hidePolicyConfirmDeleteModal();
          self.loadFrameworkControls();
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('generic_controls.framework_controls.delete.success'))
        }, function() {});
      },
      frameworkControlFormSubmit(evt) {
        this.buttonDisabled = true;
        let formData = new FormData();
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        if (this.selectedFrameworkControl && this.selectedFrameworkControl.id) {
          formData.append("framework_control_id", this.selectedFrameworkControl.id);
        }

        formData.append("genericControl", this.$route.params.slug);

        piincHttp.post('/generic-controls/' + this.$route.params.slug + '/framework-controls', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function(response) {
          self.$toastr('success', self.$t('generic_controls.framework_controls.create.success'))
          self.hideCreatePolicyModal();
          self.buttonDisabled = false;
          self.loadFrameworkControls();
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
            if (error.data.errors.framework_control_id) {
              self.error += ' ' + error.data.errors.framework_control_id[0]
            }
          }
        })
      },
      loadFrameworkItems(query) {
        let self = this;
        piincHttp.get('frameworks', { params:
          {
            client: this.getClient.slug,
            per_page: 100,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.frameworkItems = response.data.items
          if (self.frameworkItems && !self.frameworkItems.length && !query) {
            self.frameworkItems.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadFrameworkControlItems(query) {
        let self = this;
        piincHttp.get('framework-controls', { params:
          {
            client: this.getClient.slug,
            per_page: 100,
            statement_of_applicability: 1,
            framework: this.selectedFramework.id,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.frameworkControlItems = response.data.items
          if (self.frameworkControlItems && !self.frameworkControlItems.length && !query) {
            self.frameworkControlItems.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      handleFrameworkChange(item) {
        this.selectedFramework = item
        this.loadFrameworkControlItems()
      }
    },
    created () {
      if (this.getClient && this.getClient.slug) {

      }
      // Table items
      this.loadFrameworkControls();
      this.loadGuides('generic_controls_update_framework_controls');
    }
  }
</script>
